import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {FeatureFlagService} from '../feature-flag/featureflag.service';
import {map} from 'rxjs/operators';

/**
 *  Service for guarding routes protected by feature flags.
 *  If a feature flag is not enabled, the router will redirect the user to a provided url.
 *
 *  Route requires `featureFlags` array, `replaceUrl`, and `withUrl` properties.
 *
 *  Example:  if SomeFeatureFlag is not enabled, navigating to /someRoute will redirect the user to /someRoute/somewhereElse
 *  ```
 *  routes = [{
 *  path: '/someRoute',
 *  component: RestrictedPlansComponent,
 *    data: {
 *      featureFlags: ['SomeFeatureFlag'],
 *      replaceUrl: 'someRoute',
 *      withUrl: 'someRoute/somewhereElse/',
 *    },
 *  }];
 *  ```
 */
@Injectable()
export class FeatureFlagGuard implements CanActivateChild {
  constructor(private readonly _router: Router, private readonly _featureFlagService: FeatureFlagService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!route.data) {
      return of(true);
    }

    const featureFlags: string[] = route.data.featureFlags;
    const splitFlags: string[] = route.data.splitFlags;
    const replaceUrl = route.data.replaceUrl;
    const withUrl = route.data.withUrl;

    if (splitFlags?.length || featureFlags?.length) {
      if(!replaceUrl || !withUrl) {
        console.error('`replaceUrl` and `withUrl` are required for feature flag guarding.');
        return of(false);
      }

      if(featureFlags?.length) {
        return this._featureFlagService.isFeatureEnabled(featureFlags)
          .pipe(map((result) => {
            if (!result?.matchesAll) {
              const finalUrl = state.url.replace(replaceUrl, withUrl);
              this._router.navigateByUrl(finalUrl, {replaceUrl: true});
              return false;
            }

            return true;
          }));
      } else {
        return this._featureFlagService.isSplitFeatureEnabled(splitFlags)
          .pipe(map((result) => {
            if (!result?.matchesAll) {
              const finalUrl = state.url.replace(replaceUrl, withUrl).replace('//', '/');
              this._router.navigateByUrl(finalUrl, {replaceUrl: true, state: {redirected: true, redirectedTo: finalUrl}});
              return false;
            }

            return true;
          }));
      }
    } else if (replaceUrl && withUrl) {
      const finalUrl = state.url.replace(replaceUrl, withUrl);
      this._router.navigateByUrl(finalUrl, {replaceUrl: true, state: {redirected: true, redirectedTo: finalUrl}});
      return of(false);
    }
    return of(true);
  }
}
