import { Injectable, Inject } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { OEHttpClient } from '../core';
import {
  IAccount,
  IAccountHistory,
  IAccountSetAsideCashAudit,
  IAccountTradeBlockUpdate,
  IAsideCash
} from '../models/account';
import { IToken } from '../models/portfolio';
import { ConfigService } from '../config/config.service';
import { LoginAsService } from './loginas.service';
import { WINDOW } from '../providers/window.provider';
import { IDynamicGridViewState } from '../shared/gridextensions/dynamic-column-filter.component';
import { IAccountSetAsideCashSettings } from '../models/tactical';
import { RELATED_TYPE } from '../libs/app.constants';
import { IExcludedCashDetails } from '../models/modeling/model';
import { INotesHistory } from '../models/notes';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _accountSimpleEndPoint = 'v1/account/accounts/simple';
  private _accountv2SimpleEndPoint = 'v2/accountSearch';
  private _accountEndPoint = 'v1/account/accounts';
  private _accountEndPointV2 = 'v2/account/accounts';
  private _accountListV2 = 'v2/account/accounts/list';
  private _accountAuditEndPoint = 'v1/auditlog/accountSetASideCash';
  private _portfolioEndPoint = 'v1/portfolio/portfolios';
  private _restrictedPlanList = 'v1/account/accounts/restrictedPlans';
  private _houseAccountList = 'v1/account/accounts/action/houseAccount';
  private _notesHistoryEndPoint = 'v2/notes/history';
  private _dataImportEndpoint = 'v2/DataImport';

  constructor(private _httpClient: OEHttpClient, private _loginAsService: LoginAsService,
    @Inject(WINDOW) private readonly window: Window) { }

  /** Get Account Filters */
  getAccountFilters() {
    return this._httpClient.getData(`${this._accountEndPoint}/accountfilters`);
  }

  getAccountsDynamic(state: IDynamicGridViewState, filterTypeId?: number, portfolioId?: number): Observable<IAccount[]> {
    let url = this._accountListV2;
    const params = [];
    if (!!portfolioId) {
      params.push(`portfolioId=${portfolioId}`);
    }
    if (!!filterTypeId) {
      params.push(`filterId=${filterTypeId}`);
    }
    if (params.length) {
      url = `${url}?${params.join('&')}`;
    }
    return this._httpClient.postData(url, state);
  }

  /**
   * Get Account Details by AccountId
   * @accountId is type number which is the id of the account
   */
  getAccountById(accountId) {
    return this._httpClient.getData(`${this._accountEndPointV2}/${accountId}`);
  }

  /** Search account by AccountId or Name*/
  searchAccountsById(searchKey) {
    searchKey = searchKey.trim();
    if (searchKey === '') {
      return EMPTY;
    } else {
      return this._httpClient.getData(`${this._accountv2SimpleEndPoint}/accountsearchlist?search=${searchKey}&returnStyle=2`);
    }
  }

  /**
   * loads Account details by AccountId
   * Used in Account Preferences
   */
  getAccountDetail(accountId: number) {
    return this._httpClient.getData(`${this._accountSimpleEndPoint}/${accountId}`);
  }

  /**
   * To get Accounts by Account name
   * Used in Account Preferences
   */
  searchAccounts(searchString: string) {
    return this._httpClient.getData(`${this._accountSimpleEndPoint}?search=${searchString}`);
  }

  /**
   * To get Accounts by Account name
   * Used in Account Preferences
   * Account - Search Exclude SMA and Sleeve Type Account List
   */
  searchAccountsWithSMAsExcluded(searchString: string, includeDisabled: boolean = false) {
    searchString = searchString.trim();
    if (searchString === '') {
      return EMPTY;
    }
    let searchendpoint = `${this._accountSimpleEndPoint}?excludeSleeve=true&excludeSMA=true&search=${searchString}`;
    if (includeDisabled) {
      searchendpoint = `${searchendpoint}&includeDisabled=true`;
    }
    return this._httpClient.getData(searchendpoint);
  }

  /** Search account by AccountId or Name to get id, name, value*/
  searchAccountsByIdValue(searchKey, isSpendCash, excludeDoNotTrade: boolean = false) {
    return (isSpendCash)
      ? this._httpClient.getData(`${this._accountSimpleEndPoint}?excludeNeedAnalytics=true&excludeSleeve=true&excludeSMA=true&includevalue=true&inModel=true&search=${searchKey}&excludeDoNotTrade=${excludeDoNotTrade}`)
      : this._httpClient.getData(`${this._accountSimpleEndPoint}?excludeNeedAnalytics=true&excludeSleeve=true&excludeSMA=true&includevalue=true&search=${searchKey}&excludeDoNotTrade=${excludeDoNotTrade}`);
  }

  /**posts Aside Cash */
  addAsideCash(id: number, asidecash: IAsideCash) {
    return this._httpClient.postData(`${this._accountEndPoint}/${id}/asidecash`, asidecash);
  }

  /**Updata Aside Cash */
  updateAsideCash(id: number, asidecash) {
    return this._httpClient.updateData(`${this._accountEndPoint}/${id}/asidecash/${asidecash.id}`, asidecash);
  }

  /** Get Aside cash for Grid */
  getAccountAsideCash(id: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${id}/asidecash`);
  }

  /** To get AsideCashExpirationTypes */
  getCashExpiration() {
    return this._httpClient.getData(`${this._accountEndPoint}/asideCashExpirationType`);
  }

  /** To get AsideAccountTypes */
  getAccountType() {
    return this._httpClient.getData(`${this._portfolioEndPoint}/asideCashAccountType`);
  }

  /** to Get AsideCashTransactionType */
  getCashTransaction() {
    return this._httpClient.getData(`${this._accountEndPoint}/asideCashTransactionType`);
  }

  /**Get aside Cash Amount Type */
  getCashAmountType() {
    return this._httpClient.getData(`${this._accountEndPoint}/asideCashAmountType`);
  }

  /** Get AsideDetails */
  getAsideDetailsById(id: number, asidecashId: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${id}/asidecash/${asidecashId}`);
  }

  /** Delete Asidecash by id */
  deleteAsideCash(id: number, asideCash: IAsideCash): Observable<any> {
    return this._httpClient.deleteData(`${this._accountEndPoint}/${id}/asidecash/${asideCash.id}?isTacticalTradeTool=${asideCash.isTacticalTradeTool}`);
  }

  /** To get model levels for sma weightings */
  getAccountModelTypes(accountId: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${accountId}/model/modelTypes`);
  }

  /** To get sub node for model */
  getSubNodesForModel(accountId: number, modelTypeId: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${accountId}/model/submodels?modelTypeId=${modelTypeId}`);
  }

  /** To get sma list */
  getSMAList(accountId: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${accountId}/sma`);
  }

  /** Add/Update sma node details */
  updateSmaNodeDetails(accountId: number, nodes: any) {
    return this._httpClient.updateData(`${this._accountEndPoint}/${accountId}/sma`, nodes);
  }

  updateAccountDetails(accountId: number, accountDetails: any) {
    return this._httpClient.updateData(`${this._accountEndPointV2}/${accountId}/Details?withReverseSync=true`, accountDetails);
  }

  /** Get portfolio id by account id */
  getPortfolioIdByAccountId(id: number) {
    return this._httpClient.getData(`${this._accountEndPoint}/${id}/portfolioId`);
  }

  /** Get Accounts Set Aside Cash Data */
  getAccountSetAsideCashAuditData(startDate: string, endDate: string, id: number): Observable<IAccountSetAsideCashAudit[]> {
    return this._httpClient.getData(`${this._accountAuditEndPoint}/${id}?toDate=${endDate}&fromDate=${startDate}`);
  }

  /** Get Accounts Audit Data */
  getAccountHistoryData(startDate: string, endDate: string, id: number): Observable<IAccountHistory[]> {
    return this._httpClient.getData(`${this._accountEndPointV2}/${id}/history?toDate=${endDate}&fromDate=${startDate}`);
  }

  /** Get Account Notes Audit Data */
  getNotesAuditData(startDate: string, endDate: string, id: number): Observable<INotesHistory[]> {
    return this._httpClient.getData(`${this._notesHistoryEndPoint}?relatedType=${RELATED_TYPE.ACCOUNT}&relatedId=${id}&toDate=${endDate}&fromDate=${startDate}`);
  }

  /**
   * Get Simple account list based on list of account ids
   * @param selectedIds
   */
  getAccountSimpleListByIds(selectedIds: number[], inModel = false) {
    if (inModel) {
      return this._httpClient.postData(`${this._accountSimpleEndPoint}/list?includevalue=true&excludeDoNotTrade=true&excludeNeedAnalytics=true&inModel=${inModel}`, { ids: selectedIds });
    } else {
      return this._httpClient.postData(`${this._accountSimpleEndPoint}/list?includevalue=true&excludeDoNotTrade=true&excludeNeedAnalytics=true`, { ids: selectedIds });
    }
  }

  /**
   * Get Simple sleeve accounts list based on list of account ids
   * @param selectedIds
   */
  getSleeveAccountSimpleListByIds(selectedIds: number[]) {
    return this._httpClient.postData(`${this._accountSimpleEndPoint}/list?includevalue=true&inSleeve=true&excludeNeedAnalytics=true&excludeDoNotTrade=true`, { ids: selectedIds });
  }
  getRestrictedPlansList() {
    return this._httpClient.getData(this._restrictedPlanList);
  }

  /**
   * Get house accounts list based on custodian id
   * @param custodianId
   */
  getHouseAccountByCustodianId(searchKey, custodianId: number) {
    searchKey = searchKey.trim();
    if (searchKey === '') {
      return EMPTY;
    } else {
      return this._httpClient.getData(`${this._houseAccountList}?custodianId=${custodianId}&search=${searchKey}`);
    }
  }

  getAccountSetAsidePercentCalculationType() {
    return this._httpClient.getData(`${this._accountEndPoint}/accountSetAsidePercentCalculationType`);
  }

  /**
   * Opens the OC Account Edit page with the Systematics tab selected
   * @param accountId
   */
  goToAccountSystematics(accountId: any) {
    if (!accountId) {
      return;
    }
    this.getAccountById(accountId)
      .subscribe(
        (data) => {
          const ocAccountId = data.orionConnectExternalId;
          const accountFirmId = data.orionConnectFirmId;
          this._loginAsService.getFirmToken(accountFirmId)
            .subscribe((token: IToken) => {
              const url = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/integration.html?p=${encodeURIComponent(`/portfolio/edit/accounts/${ocAccountId}?isFullPage=true&showCancel=false&tabCode=systematics&m=crm`)}&t=${token.orion_access_token}`;
              this.window.open(url);
            });
        });
  }

  /**
   * Opens the OC Account editor page
   * @param accountId
   */
  goToAccountDetailsInOC(accountId: any) {
    if (!accountId) {
      return;
    }
    this.getAccountById(accountId)
      .subscribe(
        (data) => {
          const ocAccountId = data.orionConnectExternalId;
          const accountFirmId = data.orionConnectFirmId;
          this._loginAsService.getFirmToken(accountFirmId)
            .subscribe((token: IToken) => {
              const url = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/integration.html?p=${encodeURIComponent(`/portfolio/accounts?entity=7&entityId=${ocAccountId}&openEditor=true`)}&t=${token.orion_access_token}`;
              this.window.open(url);
            });
        });
  }

  /**
   * Method to fetch all accounts of a certain type.
   * @param accountType - account taxable type.
   */
  getAccountsByTaxableType(accountType: string) {
    return this._httpClient.getData(`${this._accountSimpleEndPoint}List/type/${accountType}`);
  }

  /**
   * Gets the set aside cash settings for each account passed in.
   */
  getAccountsSetAsideCashSettings(accountIds: number[]): Observable<IAccountSetAsideCashSettings[]> {
    return this._httpClient.postData(`${this._accountEndPointV2}/SetAsideCashSettings`, accountIds);
  }

  // TODO: model_tolerance_for_excluded_s3943 : Remove this API when feature flag is killed.
  /**
   * Get model tolerance set aside cash by account Id
   */
  getModelToleranceSetAsideCash(accountId: string) {
    return this._httpClient.getData(`${this._accountEndPointV2}/${accountId}/modelTolerance/setAsideCash`);
  }

    /**
   * Sends account IDs to be sync'd from OrionConnect.
   */
  runSyncAccountsFromOrionByAccountId(accountIds: number[]){
    return this._httpClient.postData( `${this._dataImportEndpoint}/Action/SyncAccounts`, accountIds);
  }

  /**
   * Updates multiple accounts with a Do Not Trade status.
   * @param accountData
   */
  setAccountTradeBlock(accountData: IAccountTradeBlockUpdate[]): Observable<any> {
    return this._httpClient.updateData(`${this._accountEndPointV2}/action/setAccountTradeBlock`, accountData);
  }

  /**
   * Updates multiple accounts with a Restricted Plan.
   * @param accountData
   */
  setAccountRestrictedPlan(accountIds: number[], restrictedPlanId?: number): Observable<any> {
    let url = `${this._accountEndPointV2}/RestrictedPlan`;
    if(restrictedPlanId) {
      url += `/${restrictedPlanId}`;
    }
    return this._httpClient.updateData(url, accountIds);
  }

  /**
   * Adds tags to a list of accounts.
   * @param accountIds
   * @param tags
   */
  public addAccountTags(accountIds: number[], tags: string[]): Observable<IAccount[]> {
    return this._httpClient.updateData(`${this._accountEndPointV2}/Tags`, {accountIds: accountIds, addTags: tags});
  }

  /**
   * Removes tags from a list of accounts
   * @param accountIds
   * @param tags
   */
  public deleteAccountTags(accountIds: number[], tags: string[]): Observable<IAccount[]> {
    return this._httpClient.updateData(`${this._accountEndPointV2}/Tags`, {accountIds: accountIds, removeTags: tags});
  }

  /**
   * Adds/removes tags for a list of accounts.
   * If a tag exists on all accounts, the tag is removed.
   * If the tag does not exist on any accounts, the tag is added to the accounts missing the tag.
   * @param tag
   * @param accounts
   */
  public setAccountsTags(tag: string, accounts: IAccount[]) {
    const allAccountsHaveTag = !!accounts.every(acct => acct.accountTags?.split(',').find(t => t === tag));
    // If all the accounts have the tag, remove it from them
    if (allAccountsHaveTag) {
      const accountIds = accounts.map(account => account.id);
      return this.deleteAccountTags(accountIds, [tag]);
    } else { // Add the tag to the accounts missing the tag.
      const accountsMissingTag = accounts.filter(acct => !acct.accountTags?.split(',').find(t => t === tag))
        .map(account => account.id);
      return this.addAccountTags(accountsMissingTag, [tag]);
    }
  }

  getModelToleranceExcludedCash(accountPortfolioId: string, pIdType: string): Observable<IExcludedCashDetails> {
    return this._httpClient.getData(`${this._accountEndPointV2}/${accountPortfolioId}/${pIdType}/modelTolerance/AccountExcludedCashDetails`);
  }
}
