import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import {
  AlertService,
  AuthGuard,
  AuthService,
  CustomSubService,
  ErrorNotificationService,
  SessionHelper
} from './core';
import { ConfigService, EnvironmentType } from './config/config.service';
import { NotificationService as AppNotificationService } from './core/customSubService';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
import { StorageModule } from '@ngx-pwa/local-storage';
import { EclipseComponent } from './eclipse.component';
import { ErrorLogComponent } from './components/errorLog/errorlog.component';
import { DataQueriesComponent } from './components/querybuilder/dataqueries/dataqueries.component';
import { ConfirmDeactivateGuard } from './components/portfolio/shared/confirm.deactivatedguard';
import { AnalyticsErrorLogComponent } from './components/analyticserrorlog/analyticserrorlog.component';
import { DataQueryStandardComponent } from './components/querybuilder/shared/dataquerystandard.component';
import { DataQueryAdvanceComponent } from './components/querybuilder/shared/dataqueryadvance.component';
import { FilterPipe } from './pipes/FilterPipe';
import { BrowserModule } from '@angular/platform-browser';
import { NotFoundComponent } from './shared/notfound/notfound.component';
import { NotAuthorizedComponent } from './shared/notauthorized/notauthorized.component';
import { LogoutComponent } from './login/logout.component';
import { FirmService } from './services/firm.service';
import { AppRoutingModule } from './app-routing.module';
import { ProgressBarCharts } from './services/progresschart.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecurityService } from './services/security.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PortfolioService } from './services/portfolio.service';
import { VersionCheckService } from './core/version-check.service';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { GlobalErrorHandler } from './core/global.error.handler';
import { LoggerFactory } from './core/logging';
import { SplitIoService } from './core/feature-flag/splitio.service';
import { NotesModule } from './components/notes/notes.module';
import { RET_LIB_API_PREFIX } from '@ret/ng-lib/shared';
import { WC_ENVIRONMENT_TOKEN, WC_IGNORE_EXCEPTIONS } from '@oat/web-components/shared';
import { CompareInterceptor } from './components/portfolio/compare-tool-wrapper/api-interceptor';
import { ConfirmationService } from 'primeng/api';
import '@oat/oat-ag-grid-controller/oat-ag-grid-controller.js';
import { DashboardModule } from "./components/dashboard/dashboard.module";

const initializeApp = (configService: ConfigService) => {
  return () => {
    return configService.load();
  };
};

export function hostOriginFactory() {
  return window.location.origin;
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    EclipseComponent,
    ErrorLogComponent,
    DataQueriesComponent,
    AnalyticsErrorLogComponent,
    DataQueryStandardComponent,
    DataQueryAdvanceComponent,
    NotFoundComponent,
    NotAuthorizedComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    LoginModule,
    DashboardModule,
    HttpClientModule,
    StorageModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 7,
      tapToDismiss: true
    }),
    NotesModule,
  ],
  providers: [CustomSubService, SecurityService, AppNotificationService, UserService, ConfigService, AuthGuard, AuthService, AlertService,
    ErrorNotificationService, ProgressBarCharts, SessionHelper, FirmService, RoleService, ConfirmDeactivateGuard, FilterPipe,
    PortfolioService, VersionCheckService, LoggerFactory, WINDOW_PROVIDERS,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, SplitIoService],
      multi: true
    },
    {provide: 'HOST_ORIGIN_NAME', useFactory: hostOriginFactory},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
            provide: RET_LIB_API_PREFIX, // MUST HAVE for DI
            useValue: '/',
        },
        {
            provide: WC_ENVIRONMENT_TOKEN, // MUST HAVE for DI
            useFactory: (config:ConfigService) => {
              if (config.isLocal()){
                return 'LOCAL';
              }
              switch (config.getEnvironment()) {
                case EnvironmentType.Test:
                case EnvironmentType.ReviewApp:
                  return 'TEST';
                case EnvironmentType.Staging:
                case EnvironmentType.RC:
                  return 'STAGE';
                case EnvironmentType.Review:
                  return 'REVIEW';
                case EnvironmentType.Production:
                  return 'PROD';
                default:
                  return 'DEV';
            }},
            deps: [ConfigService]
        },
        {
            provide: WC_IGNORE_EXCEPTIONS, // MUST HAVE for DI
            useValue: true
        }, {
          provide: HTTP_INTERCEPTORS,
          useClass: CompareInterceptor,
          multi: true
        },
        ConfirmationService  // MUST HAVE for DI
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
