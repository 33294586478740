import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve
} from "@angular/router";
import { Observable, of } from "rxjs";
import { FeatureFlagService } from './featureflag.service';
import { map } from 'rxjs/operators';

/**
 * Resolver used to ensure feature flags are available to a component when it's created.
 *
 * Usage:
 *  1. Add `resolve: { featureFlags: FeatureFlagsResolver }` to the component route.
 *  2. In the target component, add the array of feature flags to preload and the object that will receive the resolved flag values.
 *  3. In the target component constructor, fill the `featureFlags` object with resolved values.  Note the 'featureFlags' property in the route snapshot data
 *    comes from the `featureFlags` property in the route resolver set in Step 1.
 *
 *  Example
 *  routing-module.ts:
 *    {
 *      path: 'some-path',
 *      component: SomeFlaggedComponent,
 *      resolve: { featureFlags: FeatureFlagsResolver },
 *    },
 *
 *  SomeFlaggedComponent.ts:
 *  // Feature flags that should be resolved before this component is created
 *  static PRELOAD_FEATURE_FLAGS: string[] = [
 *    'Eclipse_feature_flag_name'
 *  ];
 *  // Object containing the resolved feature flag values
 *  private readonly featureFlags: { [key: string]: boolean };
 *
 *  constructor(private readonly _router: Router, private readonly activateRoute: ActivatedRoute) {
 *     this.featureFlags = activateRoute.snapshot.data['featureFlags'] || {};
 *  }
 *
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsResolver implements Resolve<{ [key: string]: boolean }> {
  constructor(private readonly _featureFlagService: FeatureFlagService) {
  }

  /**
   * Checks the route for an array of `preloadFeatureFlags`.  If present, the flags
   * are resolved and are passed to the component in the route data.
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<{ [key: string]: boolean }> {
    if(!route.component || !(<any>route.component)?.PRELOAD_FEATURE_FLAGS?.length) {
      return of({});
    }
    const flags: string[] = (<any>route.component).PRELOAD_FEATURE_FLAGS;
    return this._featureFlagService.isSplitFeatureEnabled(flags)
      .pipe(map(flagCheck => {
        const resolved = {};
        flagCheck.flags.forEach(flag => resolved[flag.key] = flag.value);
        return resolved;
      }));
  }
}

