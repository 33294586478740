<div class="p-x-5">
  <p-tabView styleClass="tabs-only" *ngIf="params?.dateRange || (params?.additionalFilters && params.additionalFilters.length)">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <a class="tab-icon" (click)="currentTab = 'view'" pTooltip="Views/Filters"><i class="fas fa-filter" aria-hidden="true"></i></a>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <a class="tab-icon" (click)="currentTab = 'daterange'" pTooltip="Date Range"><i class="fas fa-calendar-days" aria-hidden="true"></i></a>
      </ng-template>
    </p-tabPanel>
    <ng-container *ngIf="params?.additionalFilters && params.additionalFilters.length">
      <p-tabPanel  *ngFor="let tab of params.additionalFilters">
        <ng-template pTemplate="header">
          <a class="tab-icon" (click)="currentTab = tab.key"><i class="{{tab.icon || 'far fa-filter'}}" aria-hidden="true"></i></a>
        </ng-template>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
  <div>
    <!--Hide the div instead of destroying it to avoid recreating the components-->
    <div [ngStyle]="{display: currentTab === 'view' ? 'block' : 'none'}">
      <p-accordion [multiple]="true">
        <p-accordionTab header="View" [selected]="true" *ngIf="!!viewTypeId">
          <div>
            <eclipse-saved-views [viewTypeId]="viewTypeId"
                                 [extendedFilterParams]="extendedFilterParams"
                                 [model]="savedView"
                                 (parentColDefCallback)="parentComponent?.createColumnDefs()"
                                 (parentCallback)="refreshData()"
                                 (viewChanged)="viewChanged()" (viewReset)="viewReset()">
            </eclipse-saved-views>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Dashboard Filters" *ngIf="params.filter" [selected]="true">
          <div class="form-group">
            <div style="display: flex;">
              <p-dropdown ariaLabel="Filter list" *ngIf="filterList?.length" id="accFilter" class="form-control" [options]="filterList" [(ngModel)]="params.filter.filterTypeId"
                          optionValue="id" optionLabel="name" [style]="{'width': '100%'}"
                          (onChange)="onFilterChange()" appendTo="body">
              </p-dropdown>
              <div style="padding: 8px 0;">
                <button
                  *ngIf="parentComponent?.dashboardSettings && !parentComponent.dashboardSettings.hideDashboardSetting"
                  type="button" pButton icon="pi pi-window-maximize" iconPos="right"
                  (click)="parentComponent?.showDashboardSetting()">
                </button>
              </div>
            </div>
          </div>
          <div>
            <label *ngIf="params.filter.isBestPracticeFilter"> *Best Practice Filter </label>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Filters" [selected]="true">
          <div *ngIf="focusedCell" class="form-group">
            <button type="button" pButton class="p-button btn-block" (click)="applyCurrentCellToFilter()"
            pTooltip="Create a quick filter for {{ focusedCell.name }} = {{ focusedCell.value }}" tooltipPosition="bottom" showDelay="1000" >
              <i class="fas fa-filter" aria-hidden="true"></i>
              <span class="p-button-label truncate-text m-l-5">{{ focusedCell.name }} = {{ focusedCell.value }}</span>
            </button>
            <button type="button" pButton class="p-button p-button-secondary btn-block"
                    pTooltip="Copy selected cell to clipboard" tooltipPosition="bottom" showDelay="1000"
                    (click)="copyCellToClipboard()" label="Copy to Clipboard" icon="fas fa-copy">
            </button>
          </div>
          <div id="Filters">
            <div class="form-group">
              <eclipse-grid-dynamic-column-filter (filterApplied)="applyNewFilter($event)"
                                                  (filterDiscarded)="discardFilter()"
                                                  [availableColumns]="availableColumns"></eclipse-grid-dynamic-column-filter>
            </div>
            <div *ngIf="filters?.length;else no_filters">
              <div *ngFor="let f of filters" class="m-t-5 vertical-center grid-filter-item" style="gap: 5px;">
                <button pButton aria-label="Delete" title="Delete" (click)="removeFilter(f)" icon="fas fa-trash" class="p-button-danger p-button-icon-only p-button-sm"></button>
                <p-checkbox name="filter-checkbox" (onChange)="filterToggled(f)" [(ngModel)]="f.enabled"
                            class="grid-filter-checkbox truncated" label="{{f.headerName}} {{translateComparator(f)}}" binary="true"></p-checkbox>
              </div>
            </div>
            <ng-template #no_filters>
              <div>
                <em>No filters exist for this view</em>
              </div>
            </ng-template>
            <div *ngIf="filters?.length > 1" class="m-t-10" style="display: flex; flex-direction: column; gap: 3px;">
              <p-radioButton name="filters" [value]="true" [(ngModel)]="matchAll"
                             label="Match all enabled filters" (click)="matchAllChanged(true)"></p-radioButton>
              <p-radioButton name="filters" [value]="false" [(ngModel)]="matchAll"
                             label="Match any enabled filters" (click)="matchAllChanged(false)"></p-radioButton>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div *ngIf="params?.dateRange" [ngStyle]="{display: currentTab === 'daterange' ? 'inline-block' : 'none'}">
      <div class="col-12">
        <div class="form-group">
          <label>{{params.dateRange.startDateLabel || 'Start Date'}}: </label>
          <p-calendar [showIcon]="true" [monthNavigator]="true "
                      placeholder="Select Start Date" appendTo="body"
                      [yearNavigator]="true" [yearRange]="params.dateRange.getYearRange()"
                      [(ngModel)]="params.dateRange.startDate"
                      (onSelect)="params.dateRange.dateSelect ? params.dateRange.dateSelect($event,'start') : null"
                      [readonlyInput]="true"></p-calendar>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label>{{params.dateRange.endDateLabel || 'End Date'}}: </label>
          <p-calendar [showIcon]="true" [monthNavigator]="true " placeholder="Select End Date" appendTo="body"
                      [yearNavigator]="true" [yearRange]="params.dateRange.getYearRange()"
                      [(ngModel)]="params.dateRange.endDate"
                      (onSelect)="params.dateRange.dateSelect ? params.dateRange.dateSelect($event,'end') : null"
                      [readonlyInput]="true"></p-calendar>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <button type="button" pButton class="p-button p-button-sm" (click)="params.dateRange.confirmSelection()"
                  [disabled]="params.dateRange.isDisabled()">
              <span class="p-button-label">View</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngFor="let tab of params?.additionalFilters" [ngStyle]="{display: currentTab === tab.key ? 'block' : 'none'}">
      <ng-container [ngTemplateOutlet]="tab.template"></ng-container>
    </div>
  </div>
</div>
