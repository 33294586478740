import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PageHeaderComponent } from './pageheader/pageheader.component';
import { SavedViewComponent } from './savedviews/savedview.component';
import { MessageService } from 'primeng/api';
import { SignCurrencyPipe } from '@oat/web-components/shared';
import { DecimalPipe } from '@angular/common';

import {
  AbsoluteValuePipe,
  AccountViewFilter,
  AmountFormatCurrencyPipe,
  CurrencyAmountPipe,
  CurrencyMB,
  CurrencyMillionPipe,
  CurrencyOE,
  DateFormatPipe,
  FilterPipe,
  KeysPipe,
  MilliSecondsToTimePipe,
  NeedValueStatus,
  Numeric,
  OrderBy,
  Percentage,
  PercentageFormatPipe,
  PercentageWithTolerance,
  SafePipe,
  SecurityEquivalentPipe,
  SortPipe,
  SubModelNameSplicePipe,
  TextHighlightingPipe,
  TolerancePipe,
  DisableButtonPipe,
  IndexOfPipe
} from '../pipes/FilterPipe';
import { NotificationComponent, NotificationGroupFilterPipe } from './notification/notification.component';
import { NotificationItemComponent } from './notification/notificationitem.component';
import { NotificationMonitorComponent } from './notification/notification-monitor.component';
import { AlertComponent } from './alerts/alert.component';
import { GroupBarChartComponent } from './charts/groupchart/groupbarchart.component';
import { RouterModule } from '@angular/router';
import { Accounts } from './accounts/assign.accounts';
import { SetAsideCashComponent } from './accounts/set-aside-cash.component';
import { MACWeightingValidationComponent } from './macweightings/macweighting.validation.component';
import { GridRowCountComponent } from './gridextensions/gridrowcount.component';
import { SecurityWeightingComponent } from './macweightings/security.weighting.component';
import { PortfolioAutoCompleteComponent } from './search/portfolio.autocomplete.component';
import { SleevesAutoCompleteComponent } from './search/sleeves.autocomplete.component';
import { ModelAutoCompleteComponent } from './search/model.autocomplete.component';
import { DateRendererComponent } from '../core/date.renderer.component';
import { AccountsAutoCompleteComponent } from './search/account.autocomplete.component';
import { SleevePfAutoCompleteComponent } from './search/sleeveportfolio.autocomplete.component';
import { TradeGroupsPortfolioAutoCompleteComponent } from './search/tradegroupsportfolio.autocomplete.component';
import { TradeGroupsAccountAutoCompleteComponent } from './search/tradegroupsaccount.autocomplete.component';
import { TradeOrderFilterComponent } from './tradetool/tradeorder.tradefilter.component';
import { TradeGroupsAutoCompleteComponent } from './search/tradegroups.autocomplete.component';
import { ModelPortfolioComparisonComponent } from './modelPortfolioComparison/modelPortfolioComparison.component';
import { AnalysePortfolioComponent } from './modelPortfolioComparison/analysePortfolio.component';
import { TagInputComponent } from './tags/tag-input.component';
import { TagInputItemComponent } from './tags/tag-input-item.component';
import { DynamicWrapperComponent } from './dynamicform/dynamicwrapper.component';
import { DynamicControlComponent } from './dynamicform/dynamiccontrol.component';
import { DynamicControlReadyOnlyComponent } from './dynamicform/dynamiccontrolreadyonly.component';
import { CommunityStrategistComponent } from './communitystrategist/communitystrategist.component';
import { SecurityPreferenceComponent } from './security/security.preference.component';
import { RedemptionFeeComponent } from './redemptionfee/redemptionfee.component';
import { CustodianRedemptionFeeComponent } from './custodianredemptionfee/custodianredemptionfee.component';
import { TransactionFeeComponent } from './transactionfee/transactionfee.component';
import { TradeMinComponent } from './trademin/trademin.component';
import { TradeMaxComponent } from './trademax/trademax.component';
import { DividendReInvestComponent } from './dividendreinvest/dividendreinvest.component';
import { CapitalGainReInvestComponent } from './capitalgainreinvest/capitalgainreinvest.component';
import { TradeSettingsComponent } from './tradesettings/tradesettings.component';
import { DefaultPrimaryTeamComponent } from './defaultprimaryteam/defaultprimaryteam.component';
import { LocationOptimizationComponent } from './locationoptimization/locationoptimization.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortObjectsComponent } from './sortobjects/sortobjects.component';
import { SortListComponent } from './sortlist/sortlist.component';
import { MacBuyComponent } from './macbuy/macbuy.component';
import { MacSellComponent } from './macsell/macsell.component';
import { MomentModule } from 'ngx-moment';
import { SpinnerService } from './spinner/spinner.service';
import { FileImportExportComponent } from './filehandler/file.importexport.component';
import { TradeToolAnalyticsAnalyzerComponent } from './tradetoolanalyticanalyzer/tradetool.analytics.component';
import { ModelAnalyticAnalyzer } from './modelanalyticanalyzer/modelanalyticanalyzer.component';
import { NotificationService } from '../services/notification.service';
import { TradeFileImportPanelComponent } from './fileimportpanel/fileimportpanel.component';
import { DynamicModule } from 'ng-dynamic-component';
import { ModelToleranceComponent } from '../components/tradeorder/shared/modeltolerance.component';
import { QuickTradeComponent } from '../components/tradeorder/shared/quicktrade.component';
import { ModelAnalysisComponent } from '../components/tradeorder/shared/modelanalysis.component';
import { AngularSplitModule } from 'angular-split';
import { RouterHelperService } from '../core/router.helper.service';
import { TacticalTradesPopupComponent } from '../components/tradeorder/shared/tacticaltradespopup.component';
import { AgGridSelectAllRowsDirective, AgGridSelectRowOnRightClickDirective } from './gridextensions';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OEHttpClient } from '../core';
import { DashboardFilterComponent } from './dashboardfilter/dashboardfilter.component';
import { ExcludeFilterComponent } from './exclude-filter/exclude-filter.component';
import { ExtendedGridFilterToolPanel } from './gridextensions/extended-grid-filter.component';
import { DynamicColumnFilterComponent } from './gridextensions/dynamic-column-filter.component';
import { ImportService } from '../services/import.service';
import { SidebarNavComponent } from './leftnavigation/sidebar-nav.component';
import { SidebarService } from './leftnavigation/services/sidebar.service';
import { CheckboxRenderer, ChipRendererComponent, MultilineTooltipComponent } from './gridextensions';
import { SystematicMultiplierGridComponent } from './systematicmultiplier/systematic-multiplier-grid.component';
import { AnalyticsCountUpdateComponent } from './analyticscountupdate/analytics-count-update.component';
import { AnalyticsCountUpdateHubService } from './analyticscountupdate/shared/analytics-count-update-hub.service';
import { BillingCashMultiplierGridComponent } from './billingcashmultiplier/billing-cash-multiplier-grid.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicIFrameComponent } from './dynamiciframe/dynamic-iframe/dynamic-iframe.component';
import { DataTestIdDirective } from './datatestid/data-test-id.directive';
import { FeatureFlagGuard } from '../core/guard/featureflag.guard';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ChartModule } from 'primeng/chart';
import { SplitIoService } from '../core/feature-flag/splitio.service';
import { FocusRebalanceSecuritiesComponent } from './focus-rebalance-securities/focus-rebalance-securities.component';
import { CustodianAutocompleteComponent } from './search/custodian-autocomplete/custodian-autocomplete.component';
import { SecurityAutocompleteComponent } from './search/security-autocomplete/security-autocomplete.component';
import { AutocompleteHubService } from './search/shared/autocomplete-hub.service';
import { TargetedSecurityComponent } from './security/targeted-security/targeted-security.component';
import { HoldingSecurityComponent } from './security/holding-security/holding-security.component';
import { SecurityPreferenceTabComponent } from './security/security-preference-tab.component';
import { ESGRestrictedSecuritiesComponent } from './security/esg-restricted-securities/esg-restricted-securities.component';
import { SecurityPreferenceAddEditComponent } from './security/shared/security-preference-add-edit/security-preference-add-edit.component';
import { SecurityPreferenceEquivalentComponent } from './security/shared/security-preference-equivalent/security-preference-equivalent.component';
import { StackedProgressBarComponent } from './stacked-progress-bar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { EquivalentImportExportComponent } from './security/shared/equivalent-import-export/equivalent-import-export.component';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule as AG_MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { ComplianceComponent } from './compliance/compliance.component';
import { SignalService } from '../services/signal.service';
import { EntityStateChangeService } from '../services/entitystatechange.service';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TabPanelSelectedDirective } from './tabs/tabPanelSelected.directive';
import { InputNumberModule } from 'primeng/inputnumber';
import { ThemeService } from '../core/theme/theme.service';
import { TagModule } from 'primeng/tag';
import { DividerModule } from 'primeng/divider';
import { MenubarModule } from 'primeng/menubar';
import { SkeletonModule } from 'primeng/skeleton';
import { MoneyMarketAllocationComponent } from './money-market-preference/money-market-allocation/money-market-allocation.component';
import { MoneyMarketFundComponent } from './money-market-preference/money-market-fund/money-market-fund.component';
import { InheritedPreferenceComponent } from './dynamicform/shared/inherited-preference/inherited-preference.component';
import { CustomInputBoxRendererComponent } from './custom-input-box/custom-input-box-renderer.component';
import { MoneyMarketPreferenceAuditHistoryComponent } from './money-market-preference/money-market-preference-audit-history/money-market-preference-audit-history.component';
import { QuickTradeToolPanel } from './gridextensions/quick-trade-tool-panel.component';
import { ModelAnalyzeToolPanel } from './gridextensions/model-analyze-tool-panel.component';
import { PortfolioTreeComponent } from './portfoliotree/portfolio-tree.component';
import { AnalyticsDurationComponent } from './analytics-duration/analytics-duration.component';
import { EntityEditorMonitorComponent } from './entity-editor/entity-editor-monitor';
import { SmaWeightingsComponent } from './sma-weightings/sma-weightings.component';
import { OptimizeBatchStatus } from './astro/optimize/optimize.batchstatus.component';
import { AstroAccountHubService } from './astro/services/astro-account-hub.service';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { TagsComponent } from './tags/tags.component';
import { TradeLogComponent } from '../components/tradetool/tradelog/trade-log.component';
import { SelectedCountComponent } from './gridextensions/selected-count.component';
import { ToggleButtonModule } from 'primeng/togglebutton';

const pipes: any[] = [
  FilterPipe, AccountViewFilter, TolerancePipe, PercentageWithTolerance, OrderBy, SortPipe, CurrencyMillionPipe, SafePipe,
  CurrencyAmountPipe, KeysPipe, CurrencyOE, CurrencyMB, NeedValueStatus, Percentage, Numeric, MilliSecondsToTimePipe, DateFormatPipe,
  AmountFormatCurrencyPipe, SecurityEquivalentPipe, AbsoluteValuePipe, SubModelNameSplicePipe, PercentageFormatPipe,
  DisableButtonPipe, IndexOfPipe
];

const primeNgModules = [
  AccordionModule,
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  ChartModule,
  CheckboxModule,
  ChipModule,
  ColorPickerModule,
  ContextMenuModule,
  DialogModule,
  DividerModule,
  DropdownModule,
  DynamicDialogModule,
  InputSwitchModule,
  InputNumberModule,
  InputTextModule,
  InputTextareaModule,
  MenuModule,
  MenubarModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  SelectButtonModule,
  SidebarModule,
  SkeletonModule,
  SplitButtonModule,
  TableModule,
  TabViewModule,
  TagModule,
  ToggleButtonModule,
  TooltipModule,
  VirtualScrollerModule,
];

export function registerAgGridModules() {
  // Register only the ag-grid modules containing the features we use.
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    CsvExportModule,
    ExcelExportModule,
    ColumnsToolPanelModule,
    AG_MenuModule,
    RangeSelectionModule,
    RichSelectModule,
    RowGroupingModule,
    SetFilterModule,
    MultiFilterModule,
    SideBarModule,
    StatusBarModule,
  ]);
}

registerAgGridModules();

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    primeNgModules,
    RouterModule,
    DragDropModule,
    MomentModule,
    AngularSplitModule,
    AgGridModule,
    DynamicModule
  ],
  declarations: [
    PageHeaderComponent, SavedViewComponent, pipes, NotificationComponent, NotificationGroupFilterPipe, NotificationMonitorComponent, PortfolioAutoCompleteComponent,
    SleevesAutoCompleteComponent, ModelAutoCompleteComponent, NotificationItemComponent, AlertComponent, GroupBarChartComponent,
    Accounts, SecurityWeightingComponent, DateRendererComponent, AgGridSelectAllRowsDirective, AgGridSelectRowOnRightClickDirective, DataTestIdDirective, TabPanelSelectedDirective,
    MACWeightingValidationComponent, GridRowCountComponent, AccountsAutoCompleteComponent, SleevePfAutoCompleteComponent, TradeGroupsPortfolioAutoCompleteComponent, TradeOrderFilterComponent,
    TradeGroupsAccountAutoCompleteComponent, TradeGroupsAutoCompleteComponent, ModelPortfolioComparisonComponent, AnalysePortfolioComponent,
    TagInputComponent, TagInputItemComponent, TagsComponent, DynamicWrapperComponent, DynamicControlComponent, DynamicControlReadyOnlyComponent,
    CommunityStrategistComponent, LocationOptimizationComponent, SecurityPreferenceComponent, RedemptionFeeComponent,
    CustodianRedemptionFeeComponent, TransactionFeeComponent, TradeMinComponent, TradeMaxComponent, DividendReInvestComponent,
    CapitalGainReInvestComponent, TradeSettingsComponent, DefaultPrimaryTeamComponent, SortObjectsComponent, SortListComponent,
    MacBuyComponent, MacSellComponent, FileImportExportComponent, TradeToolAnalyticsAnalyzerComponent, ModelAnalyticAnalyzer,
    TradeFileImportPanelComponent, QuickTradeComponent, ModelToleranceComponent, ModelAnalysisComponent,
    TacticalTradesPopupComponent, TextHighlightingPipe, DashboardFilterComponent, ExcludeFilterComponent, OptimizeBatchStatus, ExtendedGridFilterToolPanel, DynamicColumnFilterComponent,
    SetAsideCashComponent, SidebarNavComponent, CheckboxRenderer, ChipRendererComponent, MultilineTooltipComponent, SystematicMultiplierGridComponent, BillingCashMultiplierGridComponent, AnalyticsCountUpdateComponent, DynamicIFrameComponent,
    FocusRebalanceSecuritiesComponent, CustodianAutocompleteComponent, SecurityAutocompleteComponent, TargetedSecurityComponent, HoldingSecurityComponent,
    SecurityPreferenceTabComponent, ESGRestrictedSecuritiesComponent, SecurityPreferenceAddEditComponent, SecurityPreferenceEquivalentComponent,
    StackedProgressBarComponent, EquivalentImportExportComponent, ComplianceComponent, MoneyMarketAllocationComponent, MoneyMarketFundComponent, InheritedPreferenceComponent, CustomInputBoxRendererComponent, MoneyMarketPreferenceAuditHistoryComponent,
    QuickTradeToolPanel, ModelAnalyzeToolPanel, PortfolioTreeComponent, AnalyticsDurationComponent, EntityEditorMonitorComponent, SmaWeightingsComponent, TradeLogComponent,
    SelectedCountComponent,
  ],
  exports: [
    CommonModule, AgGridModule, pipes, primeNgModules, AgGridSelectAllRowsDirective, AgGridSelectRowOnRightClickDirective, PageHeaderComponent, NotificationComponent, NotificationItemComponent,
    AlertComponent, GroupBarChartComponent, MACWeightingValidationComponent, TradeOrderFilterComponent,
    Accounts, SavedViewComponent, GridRowCountComponent, SecurityWeightingComponent, FormsModule, MomentModule, ReactiveFormsModule,
    PortfolioAutoCompleteComponent, SleevesAutoCompleteComponent, ModelAutoCompleteComponent, AngularSplitModule,
    RouterModule, DateRendererComponent, AccountsAutoCompleteComponent, SleevePfAutoCompleteComponent, TradeGroupsAutoCompleteComponent, DataTestIdDirective, TabPanelSelectedDirective,
    TradeGroupsPortfolioAutoCompleteComponent, TradeGroupsAccountAutoCompleteComponent, TagInputComponent, TagInputItemComponent, TagsComponent,
    ModelPortfolioComparisonComponent, DynamicControlComponent, CommunityStrategistComponent, LocationOptimizationComponent,
    SortObjectsComponent, SortListComponent, MacBuyComponent, MacSellComponent, SecurityPreferenceComponent,
    RedemptionFeeComponent, CustodianRedemptionFeeComponent, TransactionFeeComponent, TradeMinComponent, TradeMaxComponent,
    DividendReInvestComponent, CapitalGainReInvestComponent, TradeToolAnalyticsAnalyzerComponent, TradeSettingsComponent,
    DefaultPrimaryTeamComponent, DynamicControlReadyOnlyComponent, DynamicWrapperComponent, AnalysePortfolioComponent,
    ModelAnalyticAnalyzer, FileImportExportComponent, TradeFileImportPanelComponent, TacticalTradesPopupComponent, TextHighlightingPipe,
    DashboardFilterComponent, ExcludeFilterComponent, DynamicColumnFilterComponent, SetAsideCashComponent, SidebarNavComponent,
    SystematicMultiplierGridComponent, AnalyticsCountUpdateComponent, DynamicIFrameComponent, FocusRebalanceSecuritiesComponent, OptimizeBatchStatus,
    CustodianAutocompleteComponent, SecurityAutocompleteComponent, SecurityPreferenceTabComponent, StackedProgressBarComponent, NotificationMonitorComponent,
    EquivalentImportExportComponent, ComplianceComponent, MoneyMarketPreferenceAuditHistoryComponent, QuickTradeToolPanel, ModelAnalyzeToolPanel,
      PortfolioTreeComponent, AnalyticsDurationComponent, EntityEditorMonitorComponent, SmaWeightingsComponent, TradeLogComponent
  ],
})
export class SharedModule {
  constructor(private routerHelperService: RouterHelperService) {
  } // instantiate the RouterHelper so it's available immediately on load
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [SplitIoService, FeatureFlagGuard, SpinnerService, CurrencyPipe, NotificationService, RouterHelperService, OEHttpClient, ImportService,
        SidebarService, AnalyticsCountUpdateHubService, AutocompleteHubService, SignalService, EntityStateChangeService, ThemeService, MessageService, AstroAccountHubService, SignCurrencyPipe, DecimalPipe],
    };
  }
}
