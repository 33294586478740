<div *ngIf="availableColumns?.length">
  <p-dropdown ariaLabel="Available columns" id="columns" class="form-control" [options]="availableColumns" [(ngModel)]="filter.key"
              optionValue="key" optionLabel="colDef.headerName" [style]="{'width': '100%'}"
              [filter]="true" filterBy="colDef.headerName"
              (onChange)="filterColumnChange()" appendTo="body">
    <ng-template let-item pTemplate="selectedItem">
      {{ item.colDef.headerName }}
    </ng-template>
    <ng-template let-pc pTemplate="item">
      {{ pc.colDef.headerName }}
    </ng-template>
  </p-dropdown>
  <p-dropdown ariaLabel="Filter comparator" id="comparison" class="form-control" [options]="comparators | keyvalue: asIsOrder" [(ngModel)]="filter.comparator"
              optionValue="key" optionLabel="value.description"
              [style]="{'width': '100%'}" (onChange)="filterComparatorChange()" appendTo="body">
    <ng-template let-item pTemplate="selectedItem">
      {{ item.value.description }}
    </ng-template>
    <ng-template let-pc pTemplate="item">
      {{ pc.value.description }}
    </ng-template>
  </p-dropdown>

  <div [ngSwitch]="columnDataType">
    <div class="form-group m-t-15" style="display: flex; align-items: center;">
      <input aria-label="Filter value" id="numberValue" name="numberValue" *ngSwitchCase="dataType.Number" pInputText type="number" [(ngModel)]="value"
             placeholder="filter value (numeric)" (keyup.enter)="applyFilter()" step="any">
      <input aria-label="Filter value" id="stringValue" name="stringValue" *ngSwitchCase="dataType.String" pInputText type="text" [(ngModel)]="value"
             placeholder="filter value (string)" (keyup.enter)="applyFilter()">
      <p-dropdown ariaLabel="Filter value" id="boolValue" *ngSwitchCase="dataType.Boolean" class="form-control" [options]="booleanOptions" [(ngModel)]="value"
                  optionValue="value" optionLabel="description" [style]="{'width': '100%'}" appendTo="body">
      </p-dropdown>
      <p-dropdown ariaLabel="Filter value" id="enumValue" *ngSwitchCase="dataType.Enum" class="form-control" [options]="enumOptions" [(ngModel)]="value"
                  optionValue="value" optionLabel="description" [style]="{'width': '100%'}" appendTo="body">
      </p-dropdown>
      <button id="btnUploadList" aria-label="Import" title="Import" pButton class="p-button-secondary" *ngIf="bulkImportAllowed()"
              type="button" icon="fas fa-upload" (click)="showImport()">
      </button>
    </div>
    <div *ngIf="columnDataType === dataType.Date && filter.comparator !== 'TODAY'">
      <input aria-label="Filter value" id="dateFilter" name="dateFilter"
             *ngIf="filter.comparator === 'OLDER_THAN' || filter.comparator === 'WITHIN' || filter.comparator === 'WITHIN_AFTER'; else date_value "
             pInputText type="number" (keyup.enter)="applyFilter()"
             [(ngModel)]="value" placeholder="filter value">
      <ng-template #date_value>
        <p-calendar id="dateFilterValue" [showIcon]="true" [monthNavigator]="true" placeholder="Filter value" appendTo="body"
                    [yearNavigator]="true" [(ngModel)]="value" dataType="string" dateFormat="yy-mm-dd"></p-calendar>
      </ng-template>
    </div>
    <div class="form-group" *ngIf="columnDataType === dataType.Number && filter.comparator === 'BETWEEN'">
      <input aria-label="Second filter value" id="numberBetween" name="numberBetween" type="number" [(ngModel)]="filter.condition" placeholder="Second filter value" (keyup.enter)="applyFilter()" step="any" pInputText>
    </div>
  </div>
  <div class="text-right">
    <button type="button" pButton class="p-button-secondary p-button-sm"  data-dismiss="modal" (click)="applyFilter()"
            id="btnAdd" [disabled]="!isValid()" label="Add">
    </button>
    <button id="btnDone" type="button" pButton class="p-button-secondary p-button-sm" data-dismiss="modal" (click)="discardFilter()" label="Reset"></button>
  </div>
</div>

<p-dialog header="Import Filter Values" [(visible)]="showImportPopup" modal="true" [resizable]=true
          class="ui-dialog-md">
  <div class="ui-dialog-content col-sm-12">
    <div class="choose-file-small-box file-upload-input-style p-v-20" (dragover)='dragFile($event)'
         (drop)='dropFile($event)'>
      <span pButton class="btn-file"> Select File to Import
          <input type="file" name="model" id="model-file"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
                 (change)="fileSelected($event)" [(ngModel)]="uploadFile">
      </span>
      <br/> or drag and drop here
    </div>

    <section>
      <div class="inline-flex align-items-center">
        <h5>Paste a list of values</h5><i class="fas fa-circle-question m-l-5" aria-hidden="true" pTooltip="Use {blank} to include an empty value"></i>
      </div>
      <textarea id="valuesList" (ngModelChange)="onPastedValueListChanged($event)" pInputTextarea placeholder="One value per line" [(ngModel)]="valuesList" rows="5"></textarea>
    </section>
  </div>
  <p-footer>
    <span *ngIf="importError" class="text-danger m-r-10">{{importError}}</span>
    <button *ngIf="values?.length && valuesList?.length" id="btnApplyPastedFilters" type="button" class="p-button-primary" pButton icon="fas fa-check" (click)="applyPastedFilters()"
            label="Apply {{ values?.length }} Value{{values?.length === 1 ? '' : 's'}}"></button>
    <button id="btnCloseFilterImport" type="button" class="p-button-secondary " pButton icon="fas fa-times" (click)="showImportPopup=false"
            label="Close"></button>
  </p-footer>
</p-dialog>
