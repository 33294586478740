import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { Utils as Util } from '../../core/functions';
import * as Consts from '../../libs/app.constants';
import * as _ from 'lodash';
import {IView, ISavedGridView, IColDef} from '../../models/views';
import {ISavedView} from '../../viewModels/savedview';
import {SessionHelper} from '../../core';
import {ViewsService} from '../../services/views.service';
import {IExtendedFilterProvider} from '../gridextensions/extended-grid-filter.component';
import { GridApi, RowNode } from '@ag-grid-community/core';
import {map, tap} from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import {Observable} from 'rxjs';
import { SplitIoService } from "../../core/feature-flag/splitio.service";

export * from '../../viewModels/savedview';

@Component({
  selector: 'eclipse-saved-views',
  templateUrl: './savedview.component.html'
})
export class SavedViewComponent {
  _model: ISavedView;
  get model(): ISavedView {
    return this._model;
  }

  @Input('model')
  set model(value: ISavedView) {
    this._model = value;
    if (value && this.viewTypeId) {
      this.loadSavedViews();
    }
  }

  _viewTypeId: number = 0;
  get viewTypeId(): number {
    return this._viewTypeId;
  }
  @Input('viewTypeId')
  set viewTypeId(value: number) {
    this._viewTypeId = value;
    if (value && this.model) {
      this.loadSavedViews();
    }
    this.buildMenu();
  }

  @Input() displayControl: boolean = true;
  @Input() extendedFilterParams: IExtendedFilterProvider;

  menuItems: MenuItem[];
  view: ISavedGridView;
  savedViews: ISavedGridView[];
  isNameRequired: boolean = false;
  displaySaveViewAsDialog: boolean = false;
  isManual: boolean = false;
  filterModel: any;
  displayUpdateConfirmDialog: boolean = false;
  displayDeleteConfirmDialog: boolean = false;
  isUpdate: boolean = false;
  loggedInUserViewsCount: number = 0;
  cloneView: ISavedGridView;
  popupText: string = '';
  isViewNameExists: boolean = false;
  isSetDefault: boolean = false;
  isViewDeleted: boolean = false;
  colState: any;
  changeToId: number; // view id being switched to (but save dialogs still need to be completed)
  public canReadPublicViews: boolean = false;
  public canCreatePublicViews: boolean = false;
  public canUpdatePublicViews: boolean = false;
  public canDeletePublicViews: boolean = false;
  private enableSaveToDashboard: boolean = false;

  @Output() viewChanged = new EventEmitter();
  @Output() viewReset = new EventEmitter();
  /**
   * @deprecated Please use `viewChanged` and `viewReset` events.
   */
  @Output() parentCallback = new EventEmitter();
  @Output() parentColDefCallback = new EventEmitter();

  private readonly _viewsService: ViewsService = inject(ViewsService);
  private readonly _sessionHelper: SessionHelper = inject(SessionHelper);

  constructor() {
    this.view = <ISavedGridView>{};
    this.view.isDefault = false;
    this.view.isPublic = false;
    const publicViewPriv = this._sessionHelper.getPermission(Consts.PRIV_PUBLIC_VIEWS);
    this.canReadPublicViews = publicViewPriv?.canRead;
    this.canCreatePublicViews = publicViewPriv?.canAdd;
    this.canUpdatePublicViews = publicViewPriv?.canUpdate;
    this.canDeletePublicViews = publicViewPriv?.canDelete;
  }

  filterViews(views: ISavedGridView[]): ISavedGridView[] {
    return views.filter(sv => sv.id === 0 || !sv.isPublic || (sv.isPublic && this.canReadPublicViews));
  }

  /** Get custom view list to bind Views drop down */
  loadSavedViews() {
    this._viewsService.getSavedViewsByTypeId(this.viewTypeId)
      .pipe(map(v => this.filterViews(v)))
      .subscribe((Views: ISavedGridView[]) => {
        Views = Util.sortBy(Views);
        Views.unshift(<any>{id: 0, name: 'Standard View', viewTypeId: this.viewTypeId, isDefault: 0, canEdit: false, isPublic: true})
        this.savedViews = Views;
        const defaultView = Views.find(d => !!d.isDefault);
        /** To find default view of logged in user*/
        this.loggedInUserViewsCount = Views.length;

        // If an initial selection is desired, select it
        if (this.model.initialSavedViewId) {
          this.model.id = +this.model.initialSavedViewId;
          this.model.name = this.savedViews.find(savedView => savedView.id === this.model.id)?.name || 'Unknown';
        } else if (defaultView) {
          this.model.id = defaultView.id;
          this.model.name = defaultView.name;
        } else {
          this.model.id = 0; /** select the standard view if no default view is supplied */
        }

        this.getView(this.model.id);
      });
  }

  /** Save ag-grid modified view */
  public saveViewAs() {
    if (this.isViewNameExists) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (this.view.name != undefined) {
      this.view.name = this.view.name.trim();
    }
    if (this.view.name) {
      this._viewsService.checkViewNameExistence(this.view.name.trim(), +this.viewTypeId)
        .subscribe(m => {
          this.isViewNameExists = m.length > 0;
          if (this.view.id > 0) {
            this.isViewNameExists = false;
          }
          if (this.isViewNameExists) {
            return;
          } else {
            const gridFilters = this.model.parentGridApi.getFilterModel();
            /** To get applyed filters of a grid */
            const gridColumns = this.model.parentGridApi.getColumns();
            /** To get all columns of a grid */
            const gridColumnState = this.model.parentGridApi.getColumnState();
            /** To get column state of a grid */

              // Getting row group applied columns
            const gridRowGroups = this.model.parentGridApi.getRowGroupColumns();
            const rowGroupsKeyValList = [];
            let index = 0;
            gridRowGroups.forEach(a => {
              rowGroupsKeyValList.push({field: a.getColId(), index: index});
              index += 1;
            });

            let expandAll = false;
            if (gridRowGroups.length) {
              const nodes = [];
              this.model.parentGridApi.forEachNode(node => nodes.push(node));
              expandAll = this.getAllRowsExpanded(nodes);
            }

            // Preparing modified column defs to save custom view
            const columnDefs = <IColDef[]>[];
              gridColumns.forEach(element => {
              const columnDef = <IColDef>{};
              columnDef.id = element.getColId();
              columnDef.name = element.getColDef().headerName;
              columnDef.field = element.getColDef().field;
              columnDef.width = element.getActualWidth();
              columnDef.visible = element.isVisible();
              columnDef.sortOrder = element.getSort();
              columnDef.isSorted = element.isSortAscending();
              columnDef.isFilterActive = element.isFilterActive();
              columnDef.isColumnResized = (element.getActualWidth() !== element.getColDef().width) ? true : false;

              columnDef.isRowGroupActive = element.isRowGroupActive();

              rowGroupsKeyValList.forEach(a => {
                if (a.field === columnDef.field) {
                  columnDef.rowGroupIndex = a.index;
                }
              });
              columnDefs.push(columnDef);
            });


            const saveView = <IView>{};
            saveView.name = this.view.name;
            saveView.viewTypeId = this.viewTypeId;
            saveView.isDefault = !!this.view.isDefault;
            saveView.isPublic = !!this.view.isPublic;
            saveView.canEdit = true;
            saveView.gridColumnDefs = {filters: gridFilters, columnDefs: columnDefs, columnState: gridColumnState, expandAll: expandAll};

            // if there are extended filters, add those to the saved view too
            if (this.extendedFilterParams) {
              const ext = this.extendedFilterParams.getFilterModel();
              if (ext) {
                saveView.gridColumnDefs.extendedFilter = ext;
              }
            }

            if (this.view.id > 0) {
              saveView.id = this.view.id;
              this._viewsService.updateView(saveView)
                .subscribe((updatedView: ISavedGridView) => {
                  // To refresh 'select customm view' drop down list with newly created view
                  this.displayUpdateConfirmDialog = false;
                  this.model.id = null; // unset the selected id (to force the right selected view in the dropdown)
                  this.model.id = updatedView.id;
                  this.displaySaveViewAsDialog = false;
                  this.model.parentGridApi.getGridOption('context').isGridModified = false;
                  // To hide spinner once the view update has completed
                  this.refreshCustomViews().subscribe();
                });
            } else {
              this._viewsService.addView(saveView)
                .subscribe((newView: ISavedGridView) => {
                  // To refresh 'select custom view' drop down list with newly created view
                  this.refreshCustomViews().subscribe(() => {
                    this.resetSaveViewAsForm();
                    this.model.id = newView.id;
                    this.model.parentGridApi.getGridOption('context').isGridModified = false;
                    this.getView(newView.id);
                  });
                }, error => {
                  console.log('error : ', error);
                });
            }
          }
        });
    } else {
      this.isNameRequired = true;
    }
  }

  getAllRowsExpanded(nodes: RowNode[]): boolean {
    // get all the root level nodes
    nodes = nodes.filter(node => node.level === 0);
    // if there are root level nodes and they're all expanded, then save the
    // view with expand all
    return nodes.length > 0 && nodes.length === nodes.filter(node => node.expanded).length;
  }

  /** To refresh 'select custom view' drop down list with newly created view */
  refreshCustomViews(): Observable<ISavedGridView[]> {
    return this._viewsService.getSavedViewsByTypeId(this.viewTypeId)
      .pipe(map(v => this.filterViews(v)),
        tap((views: ISavedGridView[]) => {
        views = Util.sortBy(views);
        views.unshift(<any>{id: 0, name: 'Standard View', viewTypeId: this.viewTypeId, isDefault: 0, canEdit: false, isPublic: true});
        this.savedViews = views;
      }));
  }

  /** Fires on custom view drop down change */
  onCustomViewChange(params) {
    if (this.canSaveModifiedView()) {
      this.changeToId = params.value;
      this.isManual = true;
      this.model.exitWarning.show = true;
    } else {
      this.getView(params.value);
    }
  }

  /**
   * Returns true if the saved view has been modified and can be updated by the user.
   */
  public canSaveModifiedView(): boolean {
    return !!this.model?.parentGridApi?.getGridOption('context')?.isGridModified
      && (!this.view?.isPublic || (this.view?.isPublic && this.canUpdatePublicViews));
  }

  /** To get custom view based viewId */
  getView(viewId: number) {
    this.model.id = viewId;

    if (viewId > 0) {
      this._viewsService.getView(viewId)
        .subscribe((view: ISavedGridView) => {
          this.cloneView = JSON.parse(JSON.stringify(view)); // clone the view to prevent updating the original reference later
          this.isManual = false;
          this.view = view;
          this.view.isDefault = view.isDefault;
          this.view.isPublic = view.isPublic;
          this.convertColDef(view.gridColumnDefs);
          this.parentCallback.emit('load parent grid data');
          this.viewChanged.emit();
          this.buildMenu();
        });
    } else {
      // reset the columns.  if the parent hasn't provided an initial list of coldefs,
      // emit the parentColDefCallback so the parent can create the coldefs themselves.
      if (!this.model.parentColumnDefs) {
        this.parentColDefCallback.emit('load parent col defs');
      } else {
        this.model.parentGridApi?.setGridOption('columnDefs', []);
        this.model.parentGridApi?.setGridOption('columnDefs', this.model.parentColumnDefs);
        this.parentCallback.emit('load parent grid data');
        this.viewChanged.emit();
      }
      if (this.extendedFilterParams) {
        this.extendedFilterParams.loadFilter(null);
      }
      this.buildMenu();
    }
  }

  /** To convert as column defs */
  convertColDef(colDefs: any) {
    this.filterModel = colDefs.filters;
    if (!!colDefs.columnState) {
      this.colState = {
        state: colDefs.columnState,
        applyOrder: true,
        defaultState: { sort: null, rowGroup: false }
      };
    } else {
      this.colState = undefined;
    }
    const customColDefs = [];
    // clone the original coldefs to avoid modifying them by reference
    const backupCols = _.cloneDeep(this.model.parentColumnDefs);
    backupCols?.forEach(coldef => {
      // To restrict grouping for portfolio listing
      if (!(this.view.viewTypeId === Consts.ViewTypeEnum.PortfolioListView || this.view.viewTypeId === Consts.ViewTypeEnum.PortfolioListDynamicView)) {
        coldef.rowGroupIndex = null;
      }
      const item = colDefs.columnDefs?.find(cd => cd.field === coldef.field || coldef.colId === cd.id);

      if (!coldef.rowGroup) {
        coldef.rowGroup = false; // ensure rowGroup is set, even if it's to false, to prevent ag-grid auto grouping it
      }
      if (item) {
        coldef.width = item.width;
        coldef.hide = !item.visible;
        coldef.sort = item.sortOrder;
        if ((coldef.field === item.field) && (item.rowGroupIndex !== undefined && item.rowGroupIndex !== null)) {
          coldef.rowGroupIndex = item.rowGroupIndex;
        }
      }
      customColDefs.push(coldef);
    });

    if (this.model.parentGridOptions && this.model.parentGridApi) {
      this.model.parentGridApi.setGridOption('groupDefaultExpanded', colDefs.expandAll ? -1 : 0);
      this.model.parentGridApi.setGridOption('columnDefs', []);
      this.model.parentGridApi.setGridOption('columnDefs', customColDefs);
    }

    if (this.extendedFilterParams) {
      this.extendedFilterParams.loadFilter(colDefs.extendedFilter);
    }
  }

  /**
   * Applies the saved view filter and column state to the grid.
   * @param gridApi
   */
  public applySavedViewState(gridApi: GridApi): void {
    // Apply filters and set column state
    if (!!this?.model?.id) {
      gridApi.setFilterModel(this.filterModel);
      if (!!this.colState) {
        gridApi.applyColumnState(this.colState);
      }
    }
  }

  /**
   * Updates the saved view's cached column state with the current grid state.
   * @param gridOptions
   */
  public updateColumnState(gridApi: GridApi): void {
    if (!this.colState || !gridApi) {
      return;
    }
    this.colState.state = gridApi.getColumnState();
  }

  /** Close Save View As popup */
  closeSaveViewDialog() {
    this.resetSaveViewAsForm();
    // To load default/custom view on cancel button click
    this.getView(this.model.id);
  }

  /** To reset save view as popup form */
  private resetSaveViewAsForm() {
    this.isNameRequired = false;
    this.isViewNameExists = false;
    this.displaySaveViewAsDialog = false;
    this.view = <ISavedGridView>{};
  }

  /** Confirm when we are away from page */
  confirmClick(save: boolean) {
    if (this.isManual) { // Execute this block on 'custom view' drop down change (i.e executes only when grid has modifications)
      if (save) {
        // It will execute if user clicks YES button on popup
        this.model.exitWarning.show = false;
        this.displaySaveViewAsDialog = true;
        this.popupText = 'Save View';
      } else {
        // It will execute if user clicks NO button on popup
        this.model.exitWarning.show = false;
        this.displayUpdateConfirmDialog = false;
        this.model.id = this.changeToId;
        this.getView(this.model.id);
      }
    } else {
      // To check: Is NO button clicked on 'page navigation'
      if (this.isUpdate) {  // No need of check here, need to revisit and use else part directly
        this.isUpdate = false;
        this.displayUpdateConfirmDialog = false;
      } else {
        this.model.exitWarning.show = false;
        this.displaySaveViewAsDialog = true;
        this.model.exitWarning.observer.next(save);
        this.model.exitWarning.observer.complete();
      }
    }
  }

  /** To delete view */
  deleteView(viewId: number) {
    this._viewsService.deleteView(viewId)
      .subscribe((m: IView) => {
        this.isViewDeleted = true;
        this.loadSavedViews();
        this.displayDeleteConfirmDialog = false;
        this.view.isDefault = false;
        this.view.isPublic = false;
        this.view = <ISavedGridView>{};
      });
  }

  resetView(): void {
    if(this.cloneView) {
      this.view = this.cloneView;
    }
    if(this.view && this.view.gridColumnDefs) {
      this.convertColDef(this.view.gridColumnDefs);
    }
    this.parentCallback.emit('load parent grid data');
    this.viewReset.emit();
  }

  /** Fires on update view click  */
  onSaveView() {
    this.saveViewAs();
    this.isUpdate = true;
    if(this.cloneView) {
      this.view = this.cloneView;
    }
  }

  onSaveNewView() {
    this.popupText = 'Save New View';
    // eslint-disable-next-line eqeqeq
    if (this.cloneView != undefined) {
      this.view = this.cloneView;
      if (this.model.id === 0) {
        this.view.isDefault = false;
      }
      this.view.isPublic = false;
      this.view.name = '';
      this.view.id = 0;
    }

    if (this.isSetDefault) {
      this.isSetDefault = false;
      if (this.model.id > 0) {
        this.view.isDefault = true;
      }
    }

    this.displaySaveViewAsDialog = true;
  }

  onRenameView() {
    this.popupText = 'Rename View';
    this.displaySaveViewAsDialog = true;
  }

  /** Fires on set as default view click */
  onSetAsDefault(viewId: number) {
    this._viewsService.setAsDefaultView(viewId, this.viewTypeId)
      .subscribe(() => {
        this.isSetDefault = true;
      });
  }

  /** To hide error messages */
  hideError() {
    if (this.view.name) {
      this.isNameRequired = false;
      this.isViewNameExists = false;
    }
  }

  private addViewToDashboard(savedViewId: number): void {
    this._viewsService.addViewToDashboard(savedViewId)
      .subscribe();
  }

  buildMenu() {
    this.menuItems = [
      {
        items: [
          {
            label: 'Save Changes',
            icon: 'fas fa-fw fa-floppy-disk',
            visible: !!this.model?.id && (!this.view.isPublic || (this.view.isPublic && this.canUpdatePublicViews)),
            command: () => {
              this.onSaveView();
            }
          },
          {
            label: 'Save as New View',
            icon: 'far fa-fw fa-file',
            command: () => {
              this.onSaveNewView();
            }
          },
          {
            label: 'Rename View',
            icon: 'far fa-fw fa-pencil',
            visible: !!this.model?.id && (!this.view.isPublic || (this.view.isPublic && this.canUpdatePublicViews)),
            command: () => {
              this.onRenameView();
            }
          },
          {
            label: 'Delete View',
            icon: 'far fa-fw fa-times',
            visible: !!this.model?.id && (!this.view.isPublic || (this.view.isPublic && this.canDeletePublicViews)),
            command: () => {
              this.deleteView(this.model?.id);
            }
          },
        ]
      },
      {
        separator: true,
      },
      {
        items: [
          {
            label: 'Set as Default View',
            icon: 'far fa-fw fa-star',
            command: () => {
              this.onSetAsDefault(this.model?.id);
            }
          },
          {
            label: 'Reset View',
            icon: 'far fa-fw fa-reply',
            visible: !!this.model?.id,
            command: () => {
              this.resetView();
            }
          },
          {
            label: 'Add View To Dashboard',
            icon: 'far fa-fw fa-plus',
            disabled: !this.extendedFilterParams?.getFilterModel()?.filters?.some(filter => filter.enabled),
            visible: !!this.model?.id && !!this.model.allowAddToDashboard,
            command: () => {
              this.addViewToDashboard(this.model.id);
            }
          },
        ]
      }
    ];
  }
}
