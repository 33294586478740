/* Defines the Admin Dahboard entity */
import { ISavedGridView } from './views';

export interface IAdminDashboard {
  totalUsers: number;
  existingUsers: number;
  newUsers: number;
  totalRoles: number;
  existingRoles: number;
  newRoles: number;
  totalTeams: number;
  existingTeams: number;
  newTeams: number;
  totalCustodians: number;
  activeCustodians: number;
  declinedCustodians: number;
  PreferenceTotal: number;
  PreferenceEditedToday: number;
  PreferenceEditedInWeek: number;
  visits: number;
  todayVisits: number;
  monthlyVisits: number;
  percentageHigher: number;
}

export interface IDashboard {
  id: number;
  userId?: number;
  teamId?: number;
  dashboardDetails: IDashboardDetail[];
  isDeleted: boolean;
  createdBy: number;
  editedBy: number;
  createdDate: Date;
  editedDate: Date;
  // added by UI
  name: string;
  isPrimaryTeam: boolean;
}

export interface IDashboardDetail {
  id: number;
  dashboardId: number;
  dashboardFieldId: number;
  displayIndex: number;
  isDeleted: boolean;
  dashboardField?: IDashboardField;
  userGridViewId?: number;
  userGridView?: ISavedGridView;
  createdBy: number;
  editedBy: number;
  createdDate: Date;
  editedDate: Date;
  // added by UI
  isCustomizing: boolean;
  type: DashboardItemType;
}

export enum DashboardItemType {
  Metric,
  SavedView
}

export type DashboardEntityModule = {
  name: string;
  icon: string;
};

export interface IDashboardField {
  id: number;
  dashboardCategoryId: number;
  displayName: string;
  fieldName: string;
  filterTypeId?: number;
  filterLabelShortCode: string;
  dashboardCategory: IDashboardCategory;
  isDeleted: boolean;
  createdBy: number;
  editedBy: number;
  createdDate: Date;
  editedDate: Date;
}

export interface IDashboardCategory {
  id: number;
  name: string;
  isDeleted: boolean;
  createdBy: number;
  editedBy: number;
  createdDate: Date;
  editedDate: Date;
  // UI only
  selected: boolean;
}

export interface IDashboardSelectedField {
  id: number;
  name: string;
  selected: boolean;
  categoryName: string;
}

export enum DashboardCategory {
  Portfolios = 1,
  Accounts = 2,
  Trades = 3,
  Securities = 4,
  Models = 5,
  ASTRO = 6,
}
