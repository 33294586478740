import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {distinctUntilChanged, filter, map, mergeWith} from 'rxjs/operators';
import {SplitService} from '@splitsoftware/splitio-angular';
import {RxUtility} from '../../shared/helpers/rxjs-utilities';
import {firstValueFrom, identity, Observable, Subject, Subscription} from 'rxjs';
import {ConfigService} from '../../config/config.service';
import {IUser} from '../../models/user';
import {environment} from '../../../environments/environment';
import {Treatments} from '@splitsoftware/splitio/types/splitio';

@Injectable({
  providedIn: 'root'
})
export class SplitIoService {

  splitReady = false;
  splitSdkUpdateObs: Subscription;
  splitSdkUpdate$ = new Subject<string>();
  currentUser: IUser;

  constructor(private _authService: AuthService, public readonly splitService: SplitService) {
    this._authService.currentUser$
      // filter out null and duplicate users
      .pipe(filter(x => !!x && (!this.currentUser || this.currentUser.id !== x.id || this.currentUser.firmId !== x.firmId)))
      .subscribe(newUser => {
        this.splitReady = false;
        this.currentUser = newUser;
        this.initializeSplit(newUser);
      });
  }

  async initializeSplit(newUser) {
    await firstValueFrom(ConfigService.settings$);

    const userName_lower = newUser?.userLoginId?.toLowerCase() ?? null;

    const attributes = {
      firmId: newUser.firmId,
      userName: userName_lower,
    };

    this.splitSdkUpdateObs?.unsubscribe();

    const sdkConfig = {
      core: {
        authorizationKey: environment.splitIoKey,
        key: newUser.firmId,
      }
      //   // The following lines are very useful they will dump out
      //   //current splits and changes that come through, check the dev tools console.
      //   ,impressionListener: {
      //     logImpression:  this.logImpression
      //   },
      //   debug: true
      //   // Move the following lines as their own function
      //   logImpression(impressionData) {
      //      console.log(JSON.stringify(impressionData));
      //   }
    };

    if (this.splitService?.isSDKReady) {
      this.splitService.destroy().subscribe(() => {
        this.splitService.init(sdkConfig).subscribe(() => {
          const client = this.splitService.getSDKClient();

          client.setAttributes(attributes);

          client.ready()
          .then(() => { // wait for client ready Promise
            this.subscribeToSdkUpdates();
            this.splitReady = true;
          });
        });
      });
    } else {
      this.splitService.init(sdkConfig).subscribe(() => {
        const client = this.splitService.getSDKClient();

        client.setAttributes(attributes);

        client.ready()
        .then(() => { // wait for client ready Promise
          this.subscribeToSdkUpdates();
          this.splitReady = true;
        });
      });
    }
  }

  /**
   * Watches for updates to the Split SDK and emits the changes using an internal observable.
   * This is necessary because the flag() may be subscribed to before the Split service has fully
   * initialized, causing the mergeWith to throw an undefined exception because sdkUpdate$ is undefined.
   * @private
   */
  private subscribeToSdkUpdates() {
    this.splitSdkUpdateObs?.unsubscribe();
    this.splitSdkUpdateObs = this.splitService.sdkUpdate$.subscribe(update => {
      this.splitSdkUpdate$.next(update);
    });
  }

  /**
   * Returns an observable for that provides the initial value for a Split flag,
   * as well as any updates made to that flag in realtime.
   * @param key Split flag to query
   * @param subscribeToUpdates if true, the returned Observable remains active and emits
   * updates when the flag is updated on the Split website.
   */
  private getTreatment(key: string, subscribeToUpdates: boolean = true): Observable<string> {
    return RxUtility.waitUntil$({
      until: () => this.splitReady
    }).pipe(
      subscribeToUpdates ? mergeWith(this.splitSdkUpdate$) : identity,
      map(() => {
        return this.splitService.getTreatment(key);
      }));
  }

  /**
   * Returns an observable for that provides the initial value for the Split flags,
   * as well as any updates made to those flags in realtime.
   * @param keys Split flags to query
   * @param subscribeToUpdates if true, the returned Observable remains active and emits
   * updates when the flag(s) are updated on the Split website.
   */
  private getTreatments(keys: string[], subscribeToUpdates: boolean = true): Observable<Treatments> {
    return RxUtility.waitUntil$({
      until: () => this.splitReady
    }).pipe(
      subscribeToUpdates ? mergeWith(this.splitSdkUpdate$) : identity,
      map(() => {
        return this.splitService.getTreatments(keys);
      }));
  }

  /**
   * Returns an Observable with the initial flag value.  Does not emit values if the flag is changed.
   * @param key Split flag to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   */
  public flagEnabled(key: string, enabledValue = 'on'): Observable<boolean> {
    return this.getTreatment(key, false)
      .pipe(
        map((value) => {
          return value === enabledValue;
        }));
  }

  /**
   * Returns a stream with the initial flag value and emits updates to that flag as they occur.
   * @param key Split flag to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   *
   */
  public flagEnabled$(key: string, enabledValue = 'on'): Observable<boolean> {
    return this.getTreatment(key, true)
      .pipe(
        map((value) => {
          return value === enabledValue;
        }),
        // Since Split updates don't include what key was updated, we re-query all the keys
        // after an update message.  If the key being subscribed to didn't change as part of the
        // update, then we don't want to emit a redundant value, so distinctUntilChanged is used here.
        distinctUntilChanged());
  }

  /**
   * Queries for multiple flags simultaneously and provides a stream for any updates.
   * @param keys Split keys to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   */
  public flagsEnabled(keys: string[], enabledValue = 'on'): Observable<{ [key: string]: boolean }> {
    return this.getTreatments(keys, false)
      .pipe(
        map((treatments) => {
          const flags = {};
          Object.entries(treatments).map(([key, value]) => {
            flags[key] = value === enabledValue;
          });
          return flags;
        }));
  }

  /**
   * Queries for multiple flags simultaneously and provides a stream for any updates.
   * @param keys Split keys to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   */
  public flagsEnabled$(keys: string[], enabledValue = 'on'): Observable<{ [key: string]: boolean }> {
    return this.getTreatments(keys, true)
      .pipe(
        map((treatments) => {
          const flags = {};
          Object.entries(treatments).map(([key, value]) => {
            flags[key] = value === enabledValue;
          });
          return flags;
        }),
        // Since Split updates don't include what key was updated, we re-query all the keys
        // after an update message.  If the key being subscribed to didn't change as part of the
        // update, then we don't want to emit a redundant value, so distinctUntilChanged is used here.
        distinctUntilChanged());
  }
}
