<header>
  <div class="navbar navbar-fixed-top" id="sm-wrapped-page"><!-- id=sm-wrapped-page hack so Glia won't overlay the navbar -->
    <div class="container-fluid">
      <div class="connect-msg alert alert-dismissible alert-warning" *ngIf="showNoMergedOCFirmErrMsg">
        Please add Orion Connect Firm(s).
      </div>
      <div class="navbar-header">
        <button button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false"
                aria-controls="navbar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="logo">
          <a [routerLink]="['dashboard']">
            <p-skeleton *ngIf="!firmLogo" width="6.5em" height="1.75em"></p-skeleton>
            <img id="firm_logo" *ngIf="!!firmLogo" [src]="firmLogo" (error)="setDefaultFirmLogo()" alt="Firm Logo" />
          </a>
        </div>
      </div>

      <div id="navbar" class="collapse navbar-collapse navbar-responsive-collapse">
        <div class="navbar-search">
          <p-autoComplete [(ngModel)]="selectedSearchResult" data-test-id="selectedSearchResult" name="searchResult" [suggestions]="searchSuggestions" placeholder="looking for something?"
                          (completeMethod)="loadSearchSuggestions($event)" field="name" [delay]="1000" (onSelect)="onSearchSelect($event)" [minLength]="1" panelStyleClass="global-search"
                          [dropdown]="true" (onDropdownClick)="handleDropdown($event)" [size]="50" class="global-search" baseZIndex="2000" autoZIndex="true" title="Search" ariaLabel="Search" dropdownAriaLabel="Search options">
            <ng-template let-selectedSearchResult pTemplate="item">
              <ul class="ui-helper-clearfix show-hollding-dropdown">
                <li>
                  <i [ngClass]="selectedSearchResult.uiClass" class="fas text-info" aria-hidden="true"></i>
                  <div style="display: inline-block; margin-left: 20px" [ngClass]="{'fs-mask': selectedSearchResult.type === 'data' && (selectedSearchResult.module==='portfolio' || selectedSearchResult.module === 'account')}" [innerHTML]="selectedSearchResult.dispalyText  | textHighlighting: searchText"></div>
                </li>
              </ul>
            </ng-template>
          </p-autoComplete>

          <div class="search-dropdown" #searchOptionsDropdown [hidden]="!showSearchModuleOptions">
            <span>Search Options</span>
            <div class="checkbox">
              <label for="all">
                <input type="checkbox" data-test-id="toggleAllSearchOptions" name="all" id="all" [(ngModel)]="selectAll" (change)="toggleAll()" value="All"> ALL</label>
            </div>
            <div class="checkbox">
              <label for="portfolios">
                <input type="checkbox" data-test-id="togglePortfolioSearchOption" name="portfolios" id="portfolios" [(ngModel)]="selectPortfolios" (change)="toggleSearchOptions('Portfolios', $event)" value="Portfolios"> Portfolios</label>
            </div>
            <div class="checkbox">
              <label for="accounts">
                <input type="checkbox" data-test-id="toggleAccountSearchOption" name="accounts" id="accounts" [(ngModel)]="selectAccounts" (change)="toggleSearchOptions('Accounts', $event)" value="Accounts"> Accounts</label>
            </div>
            <div class="checkbox">
              <label for="models">
                <input type="checkbox" data-test-id="toggleModelSearchOption" name="models" id="models" [(ngModel)]="selectModels" (change)="toggleSearchOptions('Models', $event)" value="Models"> Models</label>
            </div>
          </div>
        </div>
        <div class="navbar-right m-r-0">
          <ul class="navbar-menus navbar-nav">
            <li *ngIf="versionChecker.newVersionAvailable">
              <a title="A new version of the application is available" (click)="showVersionChange()" href="javascript:void(0);">
                <p-chip label="New Version Available" styleClass="primaryAlt"></p-chip>
              </a>
            </li>
            <li *ngIf="user.actualUserId === null">
              <a title="{{firmName}}" (click)="showFirmList()" href="javascript:void(0);" data-test-id="switch-firm-link" class="truncate-text nav-item">
                <i class="fas fa-fw fa-database" aria-hidden="true"></i>
                <span class="hidden-ipad">({{firmId}}) {{firmName}}</span>
              </a>
            </li>
            <li *ngIf="user.actualUserId !== null">
              <a title="{{firmName}}" data-test-id="firm-name" class="truncate-text nav-item">
                <i class="fas fa-fw fa-database" aria-hidden="true"></i>
                <span class="hidden-ipad">({{firmId}}) {{firmName}}</span>
              </a>
            </li>
            <li class="dropdown contact-us" *ngIf="(isFirmAdmin || isOrionAdmin) && !runningLocal && displayContactUs">
              <a href="javascript:void(0);" class="truncate-text user-info nav-item" role="button" data-toggle="dropdown" data-target="#">
                <i class="fas fa-fw fa-comments" aria-hidden="true"></i>
                <span class="hidden-ipad"> Contact Us</span>
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu" role="menu" aria-label="Support">
                <span style="font-size: x-small">Please have your advisor number ready: {{advisorInfo?.value}}</span>
                <li><a href="{{orionSupportUrl}}" target="_blank" title="Eclipse Support">Orion Support</a></li>
                <li style="padding: 3px 20px">All Teams</li>
                <li  *ngFor="let chatTeam of chatTeams">
                  <a (click)="openChatWindow(chatTeam)" ><i class="fas fa-fw {{chatTeam.isOpen ? 'fa-comments text-info' : 'fa-envelope muted' }}" aria-hidden="true"></i> {{chatTeam.team }}</a>
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a href="javascript:void(0);" role="button" data-toggle="dropdown" data-target="#">
                <i class="far fa-fw fa-chart-column" aria-hidden="true"></i>
                <i *ngIf="isShowSpinner" class="fas fa-sync fa-spin fa-fw text-info" aria-hidden="true"></i>
                <span class="hidden-ipad">Analytics</span>
                <span class="caret"></span>
                <p-chip label="{{analyticCount | number}}" styleClass="warningAlt" *ngIf="!!analyticCount"></p-chip>
              </a>
              <ul class="dropdown-menu" role="menu">
                <li *ngIf="!!analyticCount" role="menuitem"><a href="javascript:void(0);" (click)="runNeedAnalytics()">Run Analytics Now</a></li>
                <li role="menuitem"><a href="javascript:void(0);" (click)="resetAnalytics()">Reset All In-Progress Analytics</a></li>
                <li role="menuitem"><a href="javascript:void(0)" [routerLink]="['/eclipse', 'analyticserrorlogs']">View Errors</a></li>
              </ul>
            </li>
            <li>
              <a href="javascript:void(0);" class="right-hbox-btn" (click)="toggleNotificationExpanded()">
                <i class="fas fa-fw fa-envelope" aria-hidden="true"></i>
                <span class="noti-label hidden-ipad"> Notifications</span>
                <span class="caret"></span>
                <p-chip label="{{msgCount | number}}" styleClass="primaryAlt" *ngIf="!!msgCount"></p-chip>
              </a>
            </li>
            <li class="dropdown">
              <a href="javascript:void(0);" class="user-info truncate-text nav-item" id="navbar-user-dropdown" role="button" data-toggle="dropdown" data-target="#">
                <img *ngIf="user?.userLogo" src="{{user.userLogo}}" class="user-avatar" alt="User Avatar" />
                <i class="fas fa-fw fa-user" aria-hidden="true" *ngIf="!user?.userLogo"></i>
                <span class="hidden-ipad">{{displayName}}</span>
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu navbar-user-dropdown-menu" role="menu" aria-label="Settings">
                <li *ngIf="isFirmAdmin || isOrionAdmin" id="firmProfile" role="menuitem">
                  <a href="javascript:void(0);" (click)="showProfilePopup()">
                    <i class="fas fa-fw fa-university" aria-hidden="true"></i> Firm Profile</a>
                </li>
                <li role="menuitem">
                  <a (click)="showUserProfile()" href="javascript:void(0);">
                    <i class="fas fa-fw fa-user" aria-hidden="true"></i> User Profile</a>
                </li>
                <li role="menuitem" class="dropdown-submenu pull-left">
                  <a href="javascript:void(0);">
                    <i class="fas fa-fw fa-palette" aria-hidden="true"></i>Theme</a>
                  <ul class="dropdown-menu">
                    <li (click)="changeTheme(themes.Light)">
                      <a href="javascript:void(0);">
                      <i aria-hidden="true" class="fas fa-fw"
                         [ngClass]="{'fa-check': themeService.currentTheme === themes.Light}"></i>
                        Light</a>
                    </li>
                    <li (click)="changeTheme(themes.Dark)">
                      <a href="javascript:void(0);">
                      <i aria-hidden="true" class="fas fa-fw"
                         [ngClass]="{'fa-check': themeService.currentTheme === themes.Dark}"></i>
                      Dark</a>
                    </li>
                  </ul>
                </li>
                <li *ngIf="user.actualUserId==null && showLoginAs" role="menuitem">
                  <a (click)="openLoginAsModal()" href="javascript:void(0);">
                    <i class="fas fa-fw fa-users" aria-hidden="true"></i> Login As</a>
                </li>
                <li *ngIf="user.actualUserId!=null" role="menuitem">
                  <a (click)="revertLoginAs()" href="javascript:void(0);">
                    <i class="fas fa-fw fa-reply" aria-hidden="true"></i> Revert</a>
                </li>
                <!-- 11495: Don't allow switching firms if logged in as someone else -->
                <li *ngIf="showSwitchFirm && user.actualUserId === null" role="menuitem">
                  <a (click)="showFirmList()" href="javascript:void(0);">
                    <i class="fas fa-fw fa-exchange" aria-hidden="true"></i> Switch Firm</a>
                </li>
                <li role="menuitem">
                  <a href="https://status.orionadvisor.com/" target="_blank">
                    <i class="far fa-fw fa-wave-pulse" aria-hidden="true"></i> System Status</a>
                </li>
                <li role="menuitem">
                  <a [routerLink]="['/logout']" href="javascript:void(0);">
                    <i class="fas fa-fw fa-power-off" aria-hidden="true"></i> Logout</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</header>

<div class="container-fluid p-0">
  <div>
    <orion-sidebar-nav (sidebarChanged)="updateSidebar($event)"></orion-sidebar-nav>
  </div>
  <div #mainContent [ngClass]="{'expanded-content': sidebarCollapsed, 'collapsed-content': !sidebarCollapsed}">
    <eclipse-optimize-batch-status></eclipse-optimize-batch-status>
    <router-outlet></router-outlet>
  </div>
</div>

<eclipse-notification-monitor (msgCounter)="getNotificationCount($event)"></eclipse-notification-monitor>

<!-- Modal -->

<p-sidebar styleClass="p-sidebar-no-header notification-sidebar" modal="false" #notificationSidebar [appendTo]="mainContent"
           [dismissible]="true" [showCloseIcon]="false" [(visible)]="notificationsExpanded" position="right" [blockScroll]="true">
  <eclipse-notification-layout *ngIf="notificationsExpanded" (msgCounter)="getNotificationCount($event)" (ShowProfilePopup)="showUserProfile()">
  </eclipse-notification-layout>
</p-sidebar>

<p-dialog [(visible)]="displayFirmProfile" modal="true" class="ui-dialog-md" header="Firm Profile"
          [resizable]=false>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group row">
        <div class="col-xs-12">
          <label for="firmName">Name: </label>
          <input pInputText id="firmName" data-test-id="firmName" name="firmName" [(ngModel)]="firmProfile.name"
                 placeholder="" [required]="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-xs-12" style="align-items: flex-start;">
          <label>Logo: </label>
          <!--Fileupload-->
          <div class="col-sm-8" *ngIf="!displayLogo">
            <div (dragover)="dragFile($event)" (drop)="dropFile($event)">
              <div class="choose-file-small-box file-upload-input-style">
								<span pButton class="p-button-sm"> Choose File
									<input type="file" name="model" id="model-file" [accept]="validMimeTypes" (change)="selectFirmProfileImage($event)">
								</span>
                <br> or drag and drop here
              </div>
            </div>
          </div>
          <em class="securityError danger" *ngIf="showFileUploadError">{{ fileUploadError }}</em>
          <div *ngIf="displayLogo" class="flex-column" style="align-items: start;">
            <a class="thumbnail col-sm-6">
              <img *ngIf="!newFirmLogo && !!firmLogo" id="firmLogo" class="image" style="display: block;" [src]="firmLogo" alt="Firm Logo"/>
              <img *ngIf="newFirmLogo" class="image" id="newfirmLogo" style="display: block;" [src]="newFirmLogo" alt="Firm Logo"/>
            </a>
            <button pButton class="p-button-sm" style="margin-top: -10px;" icon="fas fa-upload" label="Change Logo" (click)="onChangeLogo()"></button>
          </div>
          <!--End file upload-->
        </div>
      </div>
      <div>
        <div class="form-group row">
          <div class="col-xs-12">
            <label id="firmNameLabel">Firm Type: </label>
            <p-dropdown *ngIf="firmTypes?.length" [options]="firmTypes" [(ngModel)]="firmProfile.firmTypeId"
                        appendTo="body" name="firmType" id="firmType" ariaLabelledBy="firmNameLabel"
                        [disabled]="!isOrionAdmin" placeholder="Select Firm Type" optionLabel="name"
                        optionValue="id">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-xs-12">
          <p-checkbox [binary]="true" [trueValue]="1" [falseValue]="0" [(ngModel)]="firmProfile.demo" name="demo" label="Demo Firm" aria-label="Demo Firm"
                      id="demo" [disabled]="!isOrionAdmin" ngDefaultControl></p-checkbox>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-" data-dismiss="modal" (click)="saveProfile()" label="Save"></button>
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="onCancel()"
            label="Cancel"></button>
  </p-footer>
</p-dialog>

<!-- Modal UserProfile-->
<p-dialog [(visible)]="displayUserProfile" modal="true" class="ui-dialog-md" header="User Profile"
          [resizable]=false>
  <div class="row">
      <div class="form-group">
        <label class="control-label col-md-4 col-sm-12" for="fullName">Full Name:</label>
        <div class="col-md-8 col-sm-12">
          <input type="text" class="form-control" id="fullName" data-test-id="userName" name="userName" [(ngModel)]="user.name" placeholder="">
        </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <label class="control-label col-md-4 col-sm-12" for="email">Email Address:</label>
      <div class="col-md-8 col-sm-12">
        <input type="email" class="form-control" id="email" data-test-id="email" name="email" [(ngModel)]="user.email" placeholder="">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <label class="control-label col-md-4 col-sm-12" for="file-input"> Profile Image:</label>
      <div class="col-md-8 col-sm-12">
        <div (dragover)='dragProfileImage($event)' (drop)='dropProfileImage($event)' class="m-y-10">
          <div class="col-sm-12 choose-file-small-box file-upload-input-style">
							<span pButton class="p-button-sm"> Choose File
								<input type="file" id="file-input" accept="image/*" data-type="image" (change)="selectUserProfileImage($event)">
							</span>
            <br> or drag and drop here
            <p>{{userProfile.userLogo}}</p>
            <p *ngIf="isProfileImage">{{userProfile.imagePath}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <em class="securityError danger" *ngIf="showFileUploadError">{{fileUploadError}}</em>
  <p-footer>
    <button type="button" pButton data-dismiss="modal" (click)='saveUserProfile()'>Save</button>
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="closeUserModal()">Cancel</button>
  </p-footer>
</p-dialog>

<!-- Modal Login As-->
<p-dialog [(visible)]="displayLoginAs" modal="true" (onShow)="onAfterShowLoginAs($event)" class="ui-dialog-md" header="Login As" [resizable]=false>
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <div class="form-group row">
        <label class="control-label col-sm-12 col-md-3" id="searchUserLabel">User:</label>
        <div class="col-sm-12 col-md-8">
          <p-autoComplete class="autocomplete-search" #autoCompleteLoginAsSearchObject placeholder="Search User" [minLength]="1" data-test-id="searchUser" name="searchUser" [(ngModel)]="selectedUser" field="name"
                          [suggestions]="userSuggestions" (completeMethod)="loadUserSuggestions($event)" (onSelect)="onUserSelect($event)" appendTo="body" ariaLabelledBy="searchUserLabel">
            <ng-template let-user pTemplate="item">
            <span>{{ user.name }}</span>
          </ng-template></p-autoComplete>
        </div>
      </div>
    </div>
    <div class="form-group">
      <em class="securityError danger col-xs-12">{{loginAsErrorMessage}}</em>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton data-dismiss="modal" (click)="loginAs()" [disabled]="!(loginAsInfo.userId!=undefined)">Login</button>
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="closeLoginAsModal()">Cancel</button>
  </p-footer>
</p-dialog>

<!-- Switch Firm -->
<p-dialog [(visible)]="displaySwitchFirm" modal="true" class="ui-dialog-md" (onShow)="onAfterShowSwitchFirm($event)" header="Switch Firm"
          [resizable]=false>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-12" id="selectFirmLabel">Select Firm:</label>
    <div class="col-md-8 col-sm-12">
      <p-autoComplete #autoCompleteFirmSearchObject class="autocomplete-search" placeholder="Search Firm" field="name" [minLength]="1" data-test-id="searchFirm" name="searchFirm" [(ngModel)]="switchFirmObj" [suggestions]="firmsSuggestions"
                      (completeMethod)="getFirmList($event)" ariaLabelledBy="selectFirmLabel"
                      (onSelect)="firmChange($event.id)" appendTo="body">
        <ng-template let-firmSearch pTemplate="item">
          <span>{{ firmSearch.id +" :" + firmSearch.name }}</span>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
  <p class="securityError danger">{{switchFirmErrorMsg}}</p>
  <p-footer>
    <button type="button" pButton data-dismiss="modal" [disabled]='(this.switchFirmName == undefined || this.switchFirmName == null || this.switchFirmName == "")? true:disableSwitchButton'
            (click)="switchFirm()">Switch</button>
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="closeSwitchFirm()">Cancel</button>
  </p-footer>
</p-dialog>

<eclipse-alert></eclipse-alert>
<eclipse-entity-editor-monitor></eclipse-entity-editor-monitor>
