import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardEntityModule, IDashboardDetail } from '../../models/dashboard';
import { IDashboardSummary, IModalSummary } from '../../models/mainDashboard';
import { combineLatest, filter, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { ViewTypeEnum } from '../../libs/app.constants';
import { ISavedGridView, ISavedViewResult } from '../../models/views';
import { ViewsService } from '../../services/views.service';

@Component({
  selector: 'eclipse-saved-view-dashboard-item',
  templateUrl: './saved-view-dashboard-item.component.html',
  styleUrls: [
    './dashboard-item.component.scss',
    './saved-view-dashboard-item.component.scss'
  ],
})
export class SavedViewDashboardItemComponent {
  @Input() isEditing: boolean;
  @Output() onDelete: EventEmitter<IDashboardDetail> = new EventEmitter<IDashboardDetail>();
  public moduleSummary: IModalSummary;
  public percentValue: number;
  public viewTypes = ViewTypeEnum;
  public savedViewList$: Observable<ISavedGridView[]>;
  public savedViewType: ViewTypeEnum;
  public dataStream$: Observable<ISavedViewResult> = of(<ISavedViewResult>{rowCount: 0});
  private readonly _savedViewService: ViewsService = inject(ViewsService);
  private readonly _dashboardService: DashboardService = inject(DashboardService);
  private readonly _router: Router = inject(Router);
  private savedViewCache: { [key: number]: ISavedGridView[] } = {};

  private _entityModule: DashboardEntityModule;
  public get entityModule(): DashboardEntityModule {
    return this._entityModule;
  }

  private _dashboardSummary: IDashboardSummary;
  @Input()
  public set dashboardSummary(value: IDashboardSummary) {
    this._dashboardSummary = value;
    this.refreshModuleSummary();
  }

  private _detail: IDashboardDetail;
  public get detail(): IDashboardDetail {
    return this._detail;
  }

  @Input()
  public set detail(value: IDashboardDetail) {
    this._detail = value;
    this._entityModule = this.getEntityModule();
    this.refreshModuleSummary();
    this.savedViewType = <ViewTypeEnum>value.userGridView?.viewTypeId;
    this.getSavedViews();
  }

  public get moduleSummaryTotal(): number {
    if (!this.moduleSummary || !this.detail?.userGridView) {
      return 0;
    }
    return this.moduleSummary.total;
  }

  /**
   * Executes the saved view and returns the row count into the dataStream$.
   */
  refreshCount() {
    // Get the observable for the module.  The value emitted will contain the module total, which
    // is used to calculate the width of the progress bar.
    let moduleData$: Observable<any> = null;
    switch (this.detail?.userGridView?.viewTypeId) {
      case ViewTypeEnum.PortfolioListDynamicView:
        moduleData$ = this._dashboardService.portfolioDashboardData;
        break;
      case ViewTypeEnum.AccountListDynamicView:
        moduleData$ = this._dashboardService.accountDashboardData;
        break;
      default:
        this.dataStream$ = of(null);
        return;
    }

    // Create the data stream for this card.  We combine the moduleData and the saved view execution and take the latest from both.
    this.dataStream$ = combineLatest({
      moduleData: moduleData$,
      savedViewResult: this._dashboardService.getSavedViewCount(this.detail.userGridViewId)
    }).pipe(
      // Get only the saved view result, discard the module data.  We only want to make sure the module data is present
      // for the latter call to calculateValues
      map(({moduleData, savedViewResult}) => {
        return savedViewResult;
      }),
      // Since the saved view execution is managed by queue in the dashboard and we're subscribing to the queue here,
      // we have to filter to only get the saved view on this card (there could be other cards executing at the same time).
      filter((savedViewResult) => {
        return this.detail.userGridViewId === savedViewResult?.id;
      }),
      // Recalculate the progress bar values based on the new results
      tap((savedViewResult) => {
        this.calculateValues(savedViewResult.rowCount);
      }),
      // Share the value so it doesn't execute again if requested again
      shareReplay(1),
    );
  }

  /**
   * Calculates the percentage used in the progress bar.
   * @param value Result count after executing the saved view
   * @private
   */
  private calculateValues(value: number): void {
    this.percentValue = 0;
    const moduleTotal = this.moduleSummaryTotal;
    if (!moduleTotal) {
      return;
    }
    this.percentValue = (value / moduleTotal) * 100;
  }

  /**
   * Refreshes the module this card is using (portfolio, account, etc) and attempts to refresh the value
   * @private
   */
  private refreshModuleSummary(): void {
    this.moduleSummary = this._dashboardSummary?.warningsSummary.find(s => s.moduleName === this.entityModule?.name);
    if (!!this.moduleSummary) {
      this.refreshCount();
    }
  }

  /**
   * Returns the module from the moduleSummary that corresponds with the
   * dashboard category for this item.
   */
  getEntityModule(): DashboardEntityModule {
    switch (this.detail?.userGridView?.viewTypeId) {
      case ViewTypeEnum.PortfolioListDynamicView:
        return {
          name: 'Portfolios',
          icon: 'far fa-fw fa-cubes',
        };
      case ViewTypeEnum.AccountListDynamicView:
        return {
          name: 'Accounts',
          icon: 'far fa-fw fa-briefcase',
        };
    }
    return null;
  }

  public navigate(): void {
    const path = this.getNavigationPath();
    const queryParams = this.getQueryParams();
    this._router.navigate(path, queryParams);
  }

  public getNavigationPath(): string[] {
    switch (this.detail.userGridView?.viewTypeId) {
      case ViewTypeEnum.PortfolioListDynamicView:
        return ['/eclipse', 'portfolio', 'list'];
      case ViewTypeEnum.AccountListDynamicView:
        return ['/eclipse', 'account', 'list'];
      default:
        break;
    }
    return null;
  }

  public getQueryParams(): NavigationExtras {
    if (!this.detail?.userGridViewId) {
      return undefined;
    }
    return {queryParams: {viewId: this.detail.userGridViewId}};
  }

  /**
   * Sends a request to the parent container to delete this item.
   */
  public requestDelete(): void {
    this.onDelete.next(this.detail);
  }

  public getSavedViews() {
    this.savedViewList$ = of(this.savedViewCache[this.savedViewType])
      .pipe(
        switchMap((cachedData: ISavedGridView[]) => {
          if (cachedData) {
            return of(cachedData);
          }
          return this._savedViewService.getSavedViewsByTypeId(this.savedViewType, {simple: true, filterRequired: true})
            .pipe(
              tap(results => {
                results.forEach(r => r.viewName = `${r.viewName} (${r.id})`);
                this.savedViewCache[this.savedViewType] = results;
              }));
        }),
      );
  }

  public changeSavedViewType(): void {
    this.detail.userGridViewId = null;
    this.detail.userGridView = null;
    this.getSavedViews();
  }

  public onSavedViewChanged(evt: any): void {
    const view: ISavedGridView = this.savedViewCache[this.savedViewType]?.find(sv => sv.id === evt.value);
    if (!view) {
      return;
    }
    this.detail.userGridView = <ISavedGridView>{
      id: evt.value,
      viewName: view.name,
      viewTypeId: view.viewTypeId,
      isPublic: view.isPublic,
    };
    this._entityModule = this.getEntityModule();
    this.refreshCount();
  }
}
