import moment from 'moment';

export enum DataType {
  Number,
  String,
  Boolean,
  Date,
  Enum
}

export interface IFilterKey {
  description: string;
  short?: string;
}

export class FilterComparison {
  public static EQUALS: IFilterKey = {description: 'equals', short: '= #'};
  public static NOT_EQUALS: IFilterKey = {description: 'does not equal', short: '≠ #'};
  public static STARTS_WITH: IFilterKey = {description: 'starts with', short: 'starts with #'};
  public static DOES_NOT_START_WITH: IFilterKey = {description: 'does not start with', short: 'does not start with #'};
  public static ENDS_WITH: IFilterKey = {description: 'ends with', short: 'ends with #'};
  public static DOES_NOT_END_WITH: IFilterKey = {description: 'does not end with', short: 'does not end with #'};
  public static CONTAINS: IFilterKey = {description: 'contains', short: 'contains #'};
  public static DOES_NOT_CONTAIN: IFilterKey = {description: 'does not contain', short: 'does not contain #'};
  public static GREATER_THAN: IFilterKey = {description: 'is greater than', short: '> #'};
  public static GREATER_THAN_OR_EQUAL: IFilterKey = {description: 'is greater than or equal to', short: '>= #'};
  public static LESS_THAN: IFilterKey = {description: 'is less than', short: '< #'};
  public static LESS_THAN_OR_EQUAL: IFilterKey = {description: 'is less than or equal to', short: '<= #'};
  public static BETWEEN: IFilterKey = {description: 'is between', short: 'is between ##0 and ##1'};
  public static TODAY: IFilterKey = {description: 'is today'};
  public static AFTER: IFilterKey = {description: 'is after (date)', short: 'is after #'};
  public static BEFORE: IFilterKey = {description: 'is before (date)', short: 'is before #'};
  public static OLDER_THAN: IFilterKey = {description: 'is older than (days)', short: 'is older than ##0 day(s)'};
  public static WITHIN: IFilterKey = {description: 'is within (days) before', short: 'is within ##0 day(s) before'};
  public static WITHIN_AFTER: IFilterKey = {description: 'is within (days) after', short: 'is within ##0 day(s) after'};

  // create a filter description based on the filter key and values.
  // ex.  key=OLDER_THAN, values=[4] => 'is older than 4 day(s)'
  public static translate(key: string, values: any[], condition?: any, valueDescription?: string): string {
    const filter = <IFilterKey>FilterComparison[key];
    let description = filter.short || filter.description;
    if (description.indexOf('##') >= 0 && values && values.length) {
      description = description.replace(`##0`, values[0]);
      description = description.replace(`##1`, condition);
    } else if (description.indexOf('#') >= 0) {
      if (description.indexOf('#') >= 0 && values && values.length) {
        if (values.length > 1) {
          description = description.replace('#', `[${values.join()}]`);
        } else {
          description = description.replace('#', !!valueDescription ? valueDescription : values[0]);
        }
      }
    }
    return description;
  }
}

export class DateComparator {
  public static operators: { [name: string]: IFilterKey } = {
    TODAY: FilterComparison.TODAY,
    EQUALS: FilterComparison.EQUALS,
    AFTER: FilterComparison.AFTER,
    BEFORE: FilterComparison.BEFORE,
    OLDER_THAN: FilterComparison.OLDER_THAN,
    WITHIN: FilterComparison.WITHIN,
    WITHIN_AFTER: FilterComparison.WITHIN_AFTER,
  };

  /**
   * Are multiple values supported by this filter type?
   * Ex:  Account ID equals [1,3,4,5] = Yes
   * Older than [1/1/2020, 2/1/2020] = No
   * @param key
   */
  public static multipleValuesAllowed(key: string): boolean {
    return false;
  }

  /**
   * Acceptable date formats
   */
  public static dateFormats =  ['MM-DD-YYYY', 'MM-DD-YYYY HH:mm:ss', 'MM/DD/YYYY', 'MM/DD/YYYY HH:mm:ss', 'YYYY-MM-DD'];

  /**
   * Compares a data value against the filter values
   * @param values Filter value(s)
   * @param operator Comparison operator ('EQUALS', 'GREATER_THAN', etc)
   * @param dataValue Cell data value
   */
  public static compare(values: any[], operator: string, dataValue: string): boolean {
    const value = values[0];
    let date = null;
    if (new Date(dataValue).toString() !== 'Invalid Date') {
      date = moment(dataValue, DateComparator.dateFormats);
    }
    let val = null;
    if (new Date(value).toString() !== 'Invalid Date') {
      val = moment(value, DateComparator.dateFormats);
    }
    const today = moment();
    switch (DateComparator.operators[operator]) {
      case DateComparator.operators.EQUALS:
        if (value === '{blank}') {
          return dataValue === '' || !dataValue;
        }
        // check if the dates are the same day.  users can only select a date, so ignore time.
        return date && val && val.isValid() && date.isValid() && date.isSame(val, 'day');
      case DateComparator.operators.BEFORE:
        if (value === '{blank}') {
          return (dataValue || '') !== '';
        }
        return date && val && val.isValid() && date.isValid() && date.isBefore(val, 'second');
      case DateComparator.operators.AFTER:
        if (value === '{blank}') {
          return (dataValue || '') !== '';
        }
        return date && val && val.isValid() && date.isValid() && date.isAfter(val, 'second');
      case DateComparator.operators.OLDER_THAN:
        if (value === '{blank}') {
          return true;
        }
        return date && date.isValid() && date.isBefore(today.clone().subtract(+value, 'day'), 'second');
      case DateComparator.operators.WITHIN:
        if (value === '{blank}') {
          return true;
        }
        const past = today.clone().subtract(+value, 'day');
        return date && date.isValid() && date.isBetween(past, today);
      case DateComparator.operators.WITHIN_AFTER:
        if (value === '{blank}') {
          return true;
        }
        const future = today.clone().add(+value, 'day');
        return date && date.isValid() && date.isBetween(today, future);
      case DateComparator.operators.TODAY:
        return date && date.isValid() && date.isSame(today.startOf('day'), 'd');
      default:
        return false;
    }
  }
}

export class StringComparator {
  public static operators: { [name: string]: IFilterKey } = {
    EQUALS: FilterComparison.EQUALS,
    NOT_EQUALS: FilterComparison.NOT_EQUALS,
    STARTS_WITH: FilterComparison.STARTS_WITH,
    DOES_NOT_START_WITH: FilterComparison.DOES_NOT_START_WITH,
    ENDS_WITH: FilterComparison.ENDS_WITH,
    DOES_NOT_END_WITH: FilterComparison.DOES_NOT_END_WITH,
    CONTAINS: FilterComparison.CONTAINS,
    DOES_NOT_CONTAIN: FilterComparison.DOES_NOT_CONTAIN,
  };

  public static multipleValuesAllowed(key: string): boolean {
    return true;
  }

  public static compare(values: string[], operator: string, dataValue: string): boolean {
    dataValue = dataValue ? dataValue.toString().toLowerCase() : '';
    if (!values || !values.length) {
      return true;
    }

    // lowercase the filter value and replace {blank} with an empty string
    values = values.map(v => {
      if (v === '{blank}') {
        v = '';
      }
      return v.toString().toLowerCase();
    });
    switch (StringComparator.operators[operator]) {
      case StringComparator.operators.EQUALS:
        return values.some(v => v === dataValue);
      case StringComparator.operators.NOT_EQUALS:
        return values.every(v => dataValue !== v);
      case StringComparator.operators.STARTS_WITH:
        return values.some(v => dataValue.startsWith(v));
      case StringComparator.operators.DOES_NOT_START_WITH:
        return values.every(v => !dataValue.startsWith(v));
      case StringComparator.operators.ENDS_WITH:
        return values.some(v => dataValue.endsWith(v));
      case StringComparator.operators.DOES_NOT_END_WITH:
        return values.every(v => !dataValue.endsWith(v));
      case StringComparator.operators.CONTAINS:
        return values.some(v => dataValue.indexOf(v) !== -1);
      case StringComparator.operators.DOES_NOT_CONTAIN:
        return values.every(v => dataValue.indexOf(v) === -1);
      default:
        return false;
    }
  }
}

export class NumberComparator {
  public static operators: { [name: string]: IFilterKey } = {
    EQUALS: FilterComparison.EQUALS,
    NOT_EQUALS: FilterComparison.NOT_EQUALS,
    GREATER_THAN: FilterComparison.GREATER_THAN,
    GREATER_THAN_OR_EQUAL: FilterComparison.GREATER_THAN_OR_EQUAL,
    LESS_THAN: FilterComparison.LESS_THAN,
    LESS_THAN_OR_EQUAL: FilterComparison.LESS_THAN_OR_EQUAL,
    BETWEEN: FilterComparison.BETWEEN
  };

  public static multipleValuesAllowed(key: string): boolean {
    return key !== 'BETWEEN';
  }

  public static compare(values: any[], operator: string, dataValue: number, condition?: any): boolean {
    if (!values || !values.length) {
      return true;
    }
    const numberValue = +dataValue;
    switch (NumberComparator.operators[operator]) {
      case NumberComparator.operators.EQUALS:
        return values.some(v => {
          if (v === '{blank}') {
            return dataValue === null || dataValue === undefined;
          }
          return +v === numberValue;
        });
      case NumberComparator.operators.NOT_EQUALS:
        return values.every(v => {
          if (v === '{blank}') {
            return dataValue !== null;
          }
          return +v !== numberValue;
        });
      case NumberComparator.operators.GREATER_THAN:
        return values.some(v => {
          if (v === '{blank}') {
            return dataValue !== null;
          }
          return numberValue > +v;
        });
      case NumberComparator.operators.GREATER_THAN_OR_EQUAL:
        return values.some(v => {
          if (v === '{blank}') {
            return dataValue !== null;
          }
          return numberValue >= +v;
        });
      case NumberComparator.operators.LESS_THAN:
        return values.some(v => {
          if (v === '{blank}') {
            return dataValue !== null;
          }
          return numberValue < +v;
        });
      case NumberComparator.operators.LESS_THAN_OR_EQUAL:
        return values.some(v => {
          if (v === '{blank}') {
            return dataValue !== null;
          }
          return numberValue <= +v;
        });
      case NumberComparator.operators.BETWEEN:
        if (values[0] === '{blank}' || values[0] === null || condition === '{blank}' || condition === null || condition === undefined) {
          return false;
        }
        return (+values[0] < numberValue && +condition > numberValue) || (+values[0] > numberValue && +condition < numberValue);
      default:
        return false;
    }
  }
}

export class BooleanComparator {
  public static operators: { [name: string]: IFilterKey } = {
    EQUALS: FilterComparison.EQUALS
  };

  public static compare(values: any[], operator: string, dataValue: any): boolean {
    if (!values || !values.length) {
      return false;
    }
    const value = values[0];
    if (dataValue === 'Yes') {
      dataValue = true;
    } else if (dataValue === 'No') {
      dataValue = false;
    }
    if (value === '{blank}') {
      return dataValue === null || dataValue === undefined;
    }
    return !!dataValue === !!value;
  }
}

export class EnumComparator {
  public static operators: { [name: string]: IFilterKey } = {
    EQUALS: FilterComparison.EQUALS,
    NOT_EQUALS: FilterComparison.NOT_EQUALS
  };

  public static multipleValuesAllowed(key: string): boolean {
    return false;
  }

  public static compare(value: any, operator: string, dataValue: any): boolean {
    switch (EnumComparator.operators[operator]) {
      case EnumComparator.operators.EQUALS:
        return value === dataValue;
      case EnumComparator.operators.NOT_EQUALS:
        return value !== dataValue;
      default:
        return false;
    }
  }
}
