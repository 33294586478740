import {Observable} from 'rxjs';
import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import {ISavedView, SavedViewComponent} from '../savedviews/savedview.component';
import * as _ from 'lodash';
import {
  BooleanComparator, DataType,
  DateComparator,
  EnumComparator,
  FilterComparison,
  NumberComparator,
  StringComparator
} from './data-comparators';
import {DynamicColumnFilterComponent, INewColumnFilter} from './dynamic-column-filter.component';
import {
  CellFocusedEvent,
  ColDef,
  ColGroupDef,
  Column, GridApi,
  IToolPanel,
  IToolPanelParams,
  RowNode,
} from '@ag-grid-community/core';
import { ToolPanelKeys } from './basegrid';
import { ColumnEverythingChangedEvent } from '@ag-grid-community/core/dist/types/src/events';

/**
 * Interface used to represent the extended filter component.
 */
export interface IExtendedFilterProvider {
  savedViewComponent: SavedViewComponent;
  filters?: any[];
  conditionsMet(node): boolean;
  getFilterModel(): IExtendedFilter;
  loadFilter(filter: any): void;
  reset(): void;
  params: any;
}
/**
 * Interface representing the component that contains start/end date filter values.
 */
export interface IExtendedFilterDateRange {
  startDate: Date;
  startDateLabel?: string;
  endDate: Date;
  endDateLabel?: string;

  getYearRange(): string;
  isDisabled(): boolean;
  dateSelect?: (param, type: 'start' | 'end') => void;
  confirmSelection?: () => void;
}

export interface IExtendedFilterToolPanelFilter {
  filterTypeId?: number;
  filterType$: Observable<any[]>;
  // Id for the "Standard View" (i.e., no filter applied).  Some components use 0, some use undefined.
  isBestPracticeFilter?: boolean;
}

/**
 * Interface representing an additional filter appearing as a tab in the grid's Filter panel.
 */
export interface IAdditionalFilter {
  key: string;
  icon?: string;
  template: TemplateRef<ElementRef>;
}

/**
 * Interface representing the params object that the filter uses to reach into the hosting component.
 */
export interface IExtendedFilterToolPanelParams extends IToolPanelParams {
  component: any;
  viewTypeId: number;
  filter?: IExtendedFilterToolPanelFilter;
  toolPanelInstance?: ExtendedGridFilterToolPanel;

  refreshData(): void;
  filterChanged(filter: IColumnFilter): void;
  viewChanged(): void;
  viewReset(): void;

  dateRange?: IExtendedFilterDateRange;
  additionalFilters: IAdditionalFilter[];
}

export interface IColumnFilter {
  key: string;
  headerName: string;
  values?: any[];
  description?: string;
  condition?: any;
  colDef?: ColDef;
  dataType: DataType;
  apiDataType?: DataType;
  comparator: string;
  enabled: boolean;
}

export interface IExtendedFilter {
  all: boolean;
  filters: IColumnFilter[];
}

@Component({
  selector: 'eclipse-extended-grid-tool-panel',
  templateUrl: './extended-grid-filter.component.html',
  styleUrls: ['./extended-grid-filter.component.scss']
})
export class ExtendedGridFilterToolPanel implements IToolPanel, IExtendedFilterProvider {
  @ViewChild(SavedViewComponent) savedViewComponent: SavedViewComponent;
  @ViewChild(DynamicColumnFilterComponent) dynamicColumnFilterComponent: DynamicColumnFilterComponent;

  public parentComponent: any; // owning parent
  public parentGridContext: any; // owning grid's context.  ag-grid lists this as an `any` type.
  public extendedFilterParams: IExtendedFilterProvider = this; // handle to this used by the view
  public params: IExtendedFilterToolPanelParams; // params sent by the parent component
  public viewTypeId: number; // selected pre-saved view
  public filterList: any[]; // list of saved filters
  public filterType$: Observable<any[]>; // observable for filter list
  public focusedCell; // currently focused cell on the grid
  public currentTab = 'view'; // current tab in the panel
  public availableColumns: { key: string, colDef: ColDef }[]; // columns available to create a filter for
  public filters: IColumnFilter[] = []; // list of filters
  public matchAll = true; // true = all enabled filters must be true for record to pass.  false = only one filter needs to pass.
  public matchAny = false; // true = any enabled filter must be true for record to pass.  false = all enabled filters needs to pass.

  static getToolPanel(gridApi: GridApi): IExtendedFilterProvider {
    try {
      if (!!gridApi?.getSideBar()) {
        return gridApi.getToolPanelInstance(ToolPanelKeys.extendedFilter);
      }
      return null;
    } catch (error) {
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
      return null;
    }
  }

  refresh() {
    // unused in this implementation, but required by IToolPanel.
  }

  /**
   * Returns the Saved View component for this grid (if any).
   * The component may be provided as `savedView` in either the grid context, or the hosting component.
   */
  public get savedView(): ISavedView {
    return this.parentGridContext && this.parentGridContext.savedView ? this.parentGridContext.savedView : this.parentComponent.savedView;
  }

  agInit(params: IExtendedFilterToolPanelParams): void {
    this.params = params;
    this.viewTypeId = params.viewTypeId;
    const context = this.params.api.getGridOption('context');
    this.parentComponent = context?.self;
    this.parentGridContext = context;
    this.params.toolPanelInstance = this;
    this.parentGridContext.toolPanelInstance = this;
    if (params.filter) {
      this.filterType$ = params.filter.filterType$;
      params.filter.filterTypeId = +params.filter.filterTypeId;
    }
    // listen for the cellFocused event so we can allow cell data copying
    this.params.api.addEventListener('cellFocused', this.cellFocused.bind(this));
    // listen for the columnEverythingChanged event so we can get the available columns
    this.params.api.addEventListener('columnEverythingChanged', this.columnEverythingChanged.bind(this));
    if (this.filterType$) {
      this.filterType$.subscribe(model => {
        model.unshift({id: 0, name: 'Standard View'});
        this.filterList = model;
      });
    }
  }

  private columnEverythingChanged(params: ColumnEverythingChangedEvent): void {
    if (params.source !== 'gridInitializing') {
      return;
    }
    const available = this.getAvailableColumns(params.api.getColumnDefs());
    this.availableColumns = _.orderBy(available, (c) => c.colDef.headerName);
  }

  /**
   * Returns a list of all columns that can be filtered.
   * A column ColDef must have the `filter` property to be included in the list.
   * @param colDefs
   */
  getAvailableColumns(colDefs: any[]): { key: string; colDef: ColDef }[] {
    // add all filterable columns to the return list
    let cols = colDefs
      .filter(c => (<ColDef>c).filter)
      .map(c => ({key: (<ColDef>c).colId || (<ColDef>c).field, colDef: <ColDef>c}));

    // there may be groups of columns, so we have to recurse through each group and add the filterable columns to the final list
    colDefs
      .filter(c => (<ColGroupDef>c).children) // filter to get only the group coldefs
      .map(c => <ColGroupDef>c) // cast the objects
      .forEach(parentCol => cols = cols.concat(...this.getAvailableColumns(parentCol.children))); // recursively add any child columns

    return cols;
  }

  /**
   * Returns an object representing the current state of the extended filters.
   */
  getFilterModel(): IExtendedFilter {
    if (!this.filters || !this.filters.length) {
      return null;
    }
    return <IExtendedFilter>{
      all: this.matchAll,
      filters: this.filters.map(f => ({
        key: f.key,
        values: f.values,
        condition: f.condition,
        dataType: f.dataType,
        apiDataType: f.apiDataType,
        comparator: f.comparator,
        enabled: f.enabled
      }))
    };
  }

  /**
   * Copies the current selection (cell) to the clipboard.
   */
  copyCellToClipboard(): void {
    (<any>this.params.api).clipboardService.copyToClipboard();
  }

  /**
   * Creates a column filter based on the currently selected cell.
   */
  applyCurrentCellToFilter(): void {
    const colDef = this.focusedCell.colDef;
    let value = this.params.api.getValue(colDef.colId || colDef.field, this.focusedCell.rowNode);
    if (colDef.filterParams?.valueFormatter) {
      value = colDef.filterParams.valueFormatter(value);
    }
    const filter = DynamicColumnFilterComponent.createFilter(colDef, value);
    const columnFilter: IColumnFilter = {
      key: filter.key,
      headerName: filter.colDef.headerName,
      values: filter.values,
      colDef: filter.colDef,
      dataType: filter.dataType,
      apiDataType: filter.apiDataType,
      comparator: filter.comparator,
      enabled: true
    };
    if (!this.filters || !this.filters.length) {
      this.filters = [columnFilter];
    } else {
      this.filters.push(columnFilter);
    }
    this.onFilterChanged(columnFilter);
  }

  onFilterChanged(changedFilter: any = null, skipFilterChange: boolean = false): void {
    // emit a custom filter changed event if a filter changed
    // and we're not skipping the change (probably due to deleting a disabled filter)
    if(!!changedFilter && !skipFilterChange && this.params.filterChanged) {
      this.params.filterChanged(changedFilter);
    }
    // filter processing can take a while, so run onFilterChanged after the UI has updated to prevent UI lag.
    this.params.api.onFilterChanged();
  }

  /**
   * Cell Focused event.  Keeps track of the currently focused cell.
   * @param evt
   */
  cellFocused(evt: CellFocusedEvent) {
    if (evt && !!(<Column>evt.column) && (<Column>evt.column).getColDef().filter) {
      const colDef = (<Column>evt.column).getColDef();
      const rownode = evt.api.getDisplayedRowAtIndex(evt.rowIndex);
      let value = evt.api.getValue(evt.column, rownode);
      if (colDef.filterParams?.valueFormatter) {
        value = colDef.filterParams.valueFormatter(value);
      }
      this.focusedCell = {
        name: colDef.headerName,
        value: value,
        rowNode: rownode,
        colDef: colDef
      };
    } else {
      this.focusedCell = null;
    }
  }

  /**
   * Applies a new column filter to the grid.
   * @param filter
   */
  public applyNewFilter(filter: INewColumnFilter): void {
    if (!this.filters || !this.filters.length) {
      this.filters = [];
    }
    const newFilter = {
      key: filter.key,
      headerName: filter.colDef.headerName,
      description: filter.description,
      values: filter.values,
      condition: filter.condition,
      colDef: filter.colDef,
      dataType: filter.dataType,
      apiDataType: filter.apiDataType,
      comparator: filter.comparator,
      enabled: true,
    };
    this.filters.push(newFilter);
    this.onFilterChanged(newFilter);
  }

  /**
   * Removes a filter.
   */
  public discardFilter(): void {
    this.dynamicColumnFilterComponent.reset();
  }

  /*
  * Filter changes are echoed to the parent component.
   */
  public onFilterChange(): void {
    this.parentComponent?.onFilterChange?.(this.params.filter?.filterTypeId);
  }

  filterToggled(filter: IColumnFilter) {
    this.onFilterChanged(filter);
  }

  refreshData(): void {
    this.params?.refreshData?.();
  }

  viewChanged(): void {
    this.params?.viewChanged?.();
  }

  viewReset(): void {
    this.params?.viewReset?.();
  }

  removeFilter(filter: IColumnFilter) {
    this.filters = this.filters.filter(f => f !== filter);
    this.onFilterChanged(filter, !filter.enabled); // skip filter changes if the filter is disabled, since it won't affect the results
  }

  matchAllChanged(newValue: boolean) {
    this.matchAll = newValue;
    this.matchAny = !newValue;
    this.onFilterChanged({});
  }

  /**
   * Checks the values in a row node against the filters.
   * @param node
   */
  conditionsMet(node: RowNode): boolean {
    if (!this.filters || !this.filters.length) {
      return true;
    }
    const enabledFilters = this.filters.filter(f => f.enabled);
    if (this.matchAll) {
      return enabledFilters.every(f => this.isConditionMet(f, node));
    } else {
      return enabledFilters.some(f => this.isConditionMet(f, node));
    }
  }

  /**
   * Checks the values in a row node against a single filter.
   */
  isConditionMet(filter: IColumnFilter, node: RowNode): boolean {
    const dataValue = this.params.api.getValue(filter.key, node);
    switch (filter.dataType) {
      case DataType.String:
        return StringComparator.compare(filter.values, filter.comparator, dataValue);
      case DataType.Number:
        return NumberComparator.compare(filter.values, filter.comparator, dataValue, filter.condition);
      case DataType.Date:
        return DateComparator.compare(filter.values, filter.comparator, dataValue);
      case DataType.Boolean:
        return BooleanComparator.compare(filter.values, filter.comparator, dataValue);
      case DataType.Enum:
        return EnumComparator.compare(filter.values, filter.comparator, dataValue);
      default:
        return false;
    }
  }

  // translates a comparator to a user-friendly version.  EQUALS => is equal
  public translateComparator(filter: IColumnFilter): string {
    return FilterComparison.translate(filter.comparator, filter.values, filter.condition, filter.description);
  }

  public reset(): void {
    this.filters = [];
    this.matchAll = true;
    this.matchAny = false;
  }

  /**
   * Loads a list of column filters into the component.
   * @param filter
   */
  public loadFilter(filter: IExtendedFilter): void {
    this.reset();
    if (!filter?.filters?.length) {
      this.onFilterChanged();
      return;
    }

    const newFilters = [];
    this.matchAll = filter.all;
    this.matchAny = !filter.all;
    filter.filters.forEach(f => {
      const findColDef = this.availableColumns.find(col => col.key === f.key);
      if (!findColDef) {
        // column wasn't found....was it removed?
        console.warn(`Column ${f.key} not found.`);
      } else {
        const newFilter = <IColumnFilter>{
          key: f.key,
          colDef: findColDef.colDef,
          headerName: findColDef.colDef.headerName,
          values: f.values,
          condition: f.condition,
          dataType: f.dataType,
          apiDataType: f.apiDataType,
          comparator: f.comparator,
          enabled: f.enabled,
          description: f.description,
        };
        newFilters.push(newFilter);
      }
    });
    this.filters = newFilters;
    this.onFilterChanged();
  }

  /**
   * Returns true if all data fields required for display are available on the data object.
   * @param fields array of string field names that are bound to columns
   * @param data array of data bound to the grid
   */
  public static allColumnFieldsPresent(fields: string[], data: any[]): boolean {
    if (!data?.length || !fields?.length) {
      return true; // No data or no fields?  Then all fields exist!  Or none exist.  But let's not be so pessimistic.
    }
    const exampleRecord = data[0];
    const props = Object.keys(exampleRecord);
    return fields
      .filter(field => !['', null, undefined].includes(field)) // ignore columns with empty fields
      .every(field => !!props.includes(field) && exampleRecord[field] !== undefined); // all new fields must exist on the property
  }
}
