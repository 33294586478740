<a *ngIf="!isEditing" class="a-pointer" (click)="navigate()">
  <div class="dashboard-item-panel">
    <span class="detail-title" *ngIf="!!entityModule"><i class="fas fa-fw" [ngClass]="entityModule.icon"
                                                         aria-hidden="true"></i> {{ entityModule.name }}
      - {{ detail.userGridView?.viewName ?? 'Select a View' }}</span>
    <div class="progress"
         *ngIf="dataStream$ | async as data; else loadingValue">
      <div class="progress-bar model" [style.width]="(percentValue || 0) + '%'"></div>
    </div>
    <ng-template #loadingValue>
      <p-skeleton width="100%" height="1.5rem"></p-skeleton>
    </ng-template>
    <div class="detail-value-container">
      <ng-container [ngSwitch]="!!(dataStream$ | async)?.errorMessage">
        <span *ngSwitchCase="true" class="dashboard-value detail-value" [pTooltip]="(dataStream$ | async)?.errorMessage">N/A</span>
        <span *ngSwitchCase="false" class="dashboard-value detail-value">{{ (dataStream$ | async)?.rowCount || 0 | number }} {{ entityModule?.name }}</span>
      </ng-container>
      <span class="dashboard-value detail-value detail-total">{{ moduleSummaryTotal | number }} Total</span>
    </div>
  </div>
</a>

<div *ngIf="isEditing" class="dashboard-item-panel editing">
  <div class="panel-header flex">
    <ng-container *ngIf="savedViewType && detail.userGridViewId; else selectView">
      <div class="flex align-items-center" style="gap: 8px;">
        <span class="detail-title">{{ entityModule.name }}
          - {{ detail.userGridView?.viewName ?? 'Select a View' }}</span>
        <ng-container *ngIf="dataStream$ | async as data; else loading">
          <p-tag *ngIf="data.errorMessage" severity="warning" value="N/A"
                 [pTooltip]="data.errorMessage"></p-tag>
          <p-tag *ngIf="!data.errorMessage" [value]="data.rowCount || 0 | number"></p-tag>
        </ng-container>
        <ng-template #loading>
          <p-progressSpinner class="p-progress-spinner-sm m-l-10"></p-progressSpinner>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #selectView>
      <span class="detail-title">Select a View</span>
    </ng-template>

    <div class="detail-edit-buttons">
      <button type="button" pButton class="p-button-secondary action-button" (click)="requestDelete()"
              icon="fas fa-trash" pTooltip="Remove" aria-label="Remove">
      </button>
    </div>
  </div>
  <div class="flex-column">
    <span>Make a selection</span>
    <div class="view-types-container">
      <p-radioButton ariaLabel="Portfolios" name="portfolios" [value]="viewTypes.PortfolioListDynamicView"
                     [(ngModel)]="savedViewType"
                     label="Portfolios" (onClick)="changeSavedViewType()"></p-radioButton>
      <p-radioButton ariaLabel="Accounts" name="accounts" [value]="viewTypes.AccountListDynamicView"
                     [(ngModel)]="savedViewType"
                     label="Accounts" (onClick)="changeSavedViewType()"></p-radioButton>
    </div>
  </div>
  <div class="flex-column">
    <label for="selectedSavedView">Selected View</label>
    <p-dropdown *ngIf="savedViewList$ | async as options; else loadingOptions" id="selectedSavedView"
                ariaLabel="Select a view"
                [options]="options" [(ngModel)]="detail.userGridViewId" [filter]="true" filterBy="name"
                [required]="true" required
                (onChange)="onSavedViewChanged($event)" placeholder="Select a View" [disabled]="!savedViewType"
                optionValue="id" optionLabel="name" [style]="{'width': '100%'}" appendTo="body">
    </p-dropdown>
    <small *ngIf="!detail.userGridViewId" class="p-error">Required</small>
    <ng-template #loadingOptions>
      <p-skeleton width="100%" height="2.25rem"></p-skeleton>
    </ng-template>
  </div>
  <div class="item-drag-handle">
    <i class="fas fa-grip-horizontal m-r-5" cdkDragHandle aria-hidden="true"></i>
  </div>
</div>
