import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BooleanComparator,
  DataType,
  DateComparator,
  EnumComparator,
  IFilterKey,
  NumberComparator,
  StringComparator
} from './data-comparators';
import { ImportService } from '../../services/import.service';
import { ColDef } from '@ag-grid-community/core';
import { SplitIoService } from '../../core/feature-flag/splitio.service';

export interface INewColumnFilter {
  key: string;
  comparator: any;
  colDef: ColDef;
  values?: any[];
  condition?: any;
  dataType: DataType;
  apiDataType?: DataType;
  description?: string;
}

export interface IDynamicGridColumnFilter {
  comparator: 'EQUALS' | 'NOT_EQUALS' | 'STARTS_WITH' | 'DOES_NOT_START_WITH' | 'ENDS_WITH' | 'DOES_NOT_END_WITH' | 'CONTAINS' | 'DOES_NOT_CONTAIN' |
    'GREATER_THAN' | 'GREATER_THAN_OR_EQUAL' | 'LESS_THAN' | 'LESS_THAN_OR_EQUAL' | 'BETWEEN' | 'TODAY' | 'AFTER' | 'BEFORE' |
    'OLDER_THAN' | 'WITHIN' | 'WITHIN_AFTER',
  dataType: 'Number' | 'String' | 'Boolean' | 'Date' | 'Enum',
  values: any[]
}

export interface IDynamicGridColumnState {
  colId: string,
  sort?: 'asc' | 'desc' | null,
  sortIndex?: number,
  filters?: IDynamicGridColumnFilter[]
}

export interface IDynamicGridViewState {
  matchAll: boolean;
  columns: IDynamicGridColumnState[]
}

@Component({
  selector: 'eclipse-grid-dynamic-column-filter',
  templateUrl: './dynamic-column-filter.component.html'
})
export class DynamicColumnFilterComponent {
  public filter: INewColumnFilter;
  public dataType = DataType;
  public columnDataType: DataType = DataType.String;
  public showImportPopup = false;
  public comparators: { [name: string]: IFilterKey };
  public uploadFile: File;
  public value: any;
  public values: any[];
  public valuesList: string;

  public importError: string;
  public booleanOptions = [
    {
      description: 'is true',
      value: true
    },
    {
      description: 'is false',
      value: false
    }
  ];
  public enumOptions: any[] = [];

  private _availableColumns: { key: string, colDef: ColDef }[];

  @Output() filterApplied = new EventEmitter<INewColumnFilter>();
  @Output() filterDiscarded = new EventEmitter();

  @Input()
  get availableColumns() {
    return this._availableColumns;
  }
  set availableColumns(val) {
    this._availableColumns = val;
    this.reset(false);
  }

  constructor(private _importService: ImportService, private readonly _splitIoService: SplitIoService) {

  }

  public applyFilter(): void {
    if (!this.isValid()) {
      return;
    }
    if (this.value !== undefined && !this.values) {
      this.filter.values = [this.value];
      if (this.columnDataType === DataType.Enum) {
        if (this.enumOptions?.length) {
          this.filter.description = this.enumOptions?.find(v => v.value === this.value)?.description;
        }
      }
    } else if (this.values && this.values.length) {
      this.filter.values = this.values;
    } else {
      this.filter.values = ['{blank}'];
    }
    this.filterApplied.emit(this.filter);
    this.reset(true);
  }

  public discardFilter(): void {
    this.filterDiscarded.emit();
    this.reset(false);
  }

  public filterComparatorChange() {
  }

  /**
   * User changed the column being filtered.  Resets the comparators.
   */
  public filterColumnChange() {
    this.filter.colDef = this.availableColumns.filter(c => c.key === this.filter.key)[0].colDef;
    this.columnDataType = DynamicColumnFilterComponent.getDataTypeFromColumn(this.filter.colDef);
    this.filter.dataType = this.columnDataType;
    this.filter.apiDataType = DynamicColumnFilterComponent.getDataTypeFromName(this.filter.colDef?.filterParams?.apiDataType);
    this.value = undefined;
    this.values = undefined;
    this.filter.condition = undefined;
    this.comparators = DynamicColumnFilterComponent.getComparatorsForDataType(this.columnDataType);
    this.filter.comparator = Object.keys(this.comparators)[0];
    if (this.columnDataType === DataType.Enum) {
      if (this.filter.colDef?.filterParams?.enumOptions?.length) {
        this.enumOptions = this.filter.colDef?.filterParams?.enumOptions;
      } else if (this.filter.colDef?.filterParams?.enumType) {
        this.enumOptions = [];
        Object.entries(this.filter.colDef.filterParams.enumType).forEach(([key, value]) => {
          console.log(key, value)
        });
        for (const value in this.filter.colDef.filterParams.enumType) {
          this.enumOptions.push({value: value, description: value});
        }
      }
    }
  }

  /**
   * Is bulk importing of allowed for the current column?
   */
  public bulkImportAllowed(): boolean {
    if (!this.filter || !this.filter.comparator || this.columnDataType === null) {
      return false;
    }
    switch (this.columnDataType) {
      case DataType.String:
        return StringComparator.multipleValuesAllowed(this.filter.comparator);
      case DataType.Number:
        return NumberComparator.multipleValuesAllowed(this.filter.comparator);
      case DataType.Date:
        return DateComparator.multipleValuesAllowed(this.filter.comparator);
      case DataType.Boolean:
        return false; // it's true or false...that's all you need.
      case DataType.Enum:
        return EnumComparator.multipleValuesAllowed(this.filter.comparator);
      default:
        return false;
    }
  }

  // Hack to Angular's keyvalue pipe to preserve the object order in the dictionary.
  // By default the pipe sorts alphabetically, which is undesirable behavior.
  public asIsOrder(a, b) {
    return 1;
  }

  /**
   * Creates a new column filter object.
   * @param colDef
   * @param value
   */
  public static createFilter(colDef: ColDef, value: any): INewColumnFilter {
    return <INewColumnFilter>{
      key: colDef.colId || colDef.field,
      colDef: colDef,
      headerName: colDef.headerName,
      values: [value],
      condition: undefined,
      dataType: DynamicColumnFilterComponent.getDataTypeFromColumn(colDef),
      apiDataType: DynamicColumnFilterComponent.getDataTypeFromName(colDef.filterParams?.apiDataType),
      comparator: 'EQUALS'
    };
  }

  /**
   * Resets the current selections.
   */
  public reset(keepSelectedColumn: boolean = false): void {
    if (!this.availableColumns?.length) {
      // nothing to reset to, so bail
      return;
    }
    let selectedColumn = this.availableColumns[0];
    if (keepSelectedColumn && !!this.filter?.key && !!this.availableColumns.find(c => c.key === this.filter.key)) {
      selectedColumn = this.availableColumns.find(c => c.key === this.filter.key);
    }
    this.columnDataType = DynamicColumnFilterComponent.getDataTypeFromColumn(selectedColumn.colDef);
    this.comparators = DynamicColumnFilterComponent.getComparatorsForDataType(this.columnDataType);
    this.value = undefined;
    this.values = undefined;
    this.filter = {
      key: selectedColumn.key,
      comparator: Object.keys(this.comparators)[0],
      dataType: this.columnDataType,
      apiDataType: DynamicColumnFilterComponent.getDataTypeFromName(selectedColumn.colDef.filterParams?.dataType?.apiDataType),
      colDef: selectedColumn.colDef,
      condition: undefined,
      values: null
    };
  }

  /**
   * Returns the list of data comparators for a given data type.
   * @param dataType
   */
  private static getComparatorsForDataType(dataType: DataType) {
    switch (dataType) {
      case DataType.Boolean:
        return BooleanComparator.operators;
      case DataType.Date:
        return DateComparator.operators;
      case DataType.Number:
        return NumberComparator.operators;
      case DataType.String:
        return StringComparator.operators;
      case DataType.Enum:
        return EnumComparator.operators;
      default:
        return StringComparator.operators;
    }
  }

  private static getDataTypeFromName(dataTypeName: string): DataType {
    if (!dataTypeName) {
      return null;
    }
    switch (dataTypeName) {
      case 'boolean':
        return DataType.Boolean;
      case 'string':
        return DataType.String;
      case 'date':
        return DataType.Date;
      case 'number':
        return DataType.Number;
      case 'enum':
        return DataType.Enum;
      default:
        console.warn(`Column data type not recognized: ${dataTypeName}`);
        return null;
    }
  }

  /**
   * Returns a columns data type, as inferred by either specifying the type in the coldef filterParams,
   * or by the filter type on the column.  If no type can be determined, String is defaulted.
   * @param colDef
   */
  private static getDataTypeFromColumn(colDef): DataType {
    if (colDef.filterParams && colDef.filterParams.dataType) {
      const dataType = DynamicColumnFilterComponent.getDataTypeFromName(colDef.filterParams.dataType);
      if (!!dataType) {
        return dataType;
      }
    }
    if (colDef.filter) {
      switch (colDef.filter) {
        case 'agTextColumnFilter':
          return DataType.String;
        case 'agNumberColumnFilter':
          return DataType.Number;
        case true: // filter: true -> default to String
          return DataType.String;
      }
    }
    return DataType.String; // default to string if datatype couldn't be determined
  }

  /*
  Filter must contain a comparator and a column.  Value is optional, since it can be blank.
   */
  isValid(): boolean {
    return this.filter.comparator && this.filter.colDef !== undefined;
  }

  showImport(): void {
    this.importError = null;
    this.showImportPopup = true;
  }

  /** Fires when file is dragged */
  dragFile(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /** Fires when file is dropped */
  dropFile(event) {
    this.importFile(event.dataTransfer.files[0]);
    event.preventDefault();
    event.stopPropagation();
  }

  fileSelected(event) {
    this.importFile(event.target.files[0]);
  }

  /**
   * Runs a file (Excel, csv) through the echo endpoint to get back the fields in the file.
   * The results of the echo are applied to the filter values on the current column.
   * @param file
   */
  importFile(file) {
    this.importError = null;
    this._importService.echoFile(file).subscribe(result => {
      const header = this.filter.colDef.headerName.toLowerCase();
      const colId = this.filter.key.toLowerCase();

      this.values = (<any>Object.values(result[0].result)[0])
        .filter(x => Object.keys(x)[0].toLowerCase() === header || Object.keys(x)[0].toLowerCase() === colId)
        .map(x => {
          return Object.values(x)[0];
        });

      if (this.values.length) {
        this.applyFilter();
        this.showImportPopup = false;
      } else {
        this.importError = `No values were found for the ${this.filter.colDef.headerName} column.`;
      }
    }, error => {
      this.importError = error;
      this.uploadFile = null;
    }, () => {
      this.uploadFile = null;
    });
  }

  /**
   * On text change in pasted value list.  Splits that list by newline character and removes any
   * empty strings.
   * @param evt
   */
  onPastedValueListChanged(evt) {
    this.values = evt.split('\n').filter((x: string) => x?.length);
  }

  /**
   * Applies the filters that have been pasted to the column
   */
  applyPastedFilters() {
    this.applyFilter();
    this.showImportPopup = false;
    this.valuesList = null;
  }
}
